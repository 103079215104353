import React, { useState } from "react";
import s from "./cardSeller.module.scss";
import rus from "../../app/assets/Seller/Russia.svg";
import uk from "../../app/assets/Seller/UK.svg";
import spain from "../../app/assets/Seller/Spain.svg";
import UAE from "../../app/assets/Seller/Saud.svg";
import israel from "../../app/assets/Seller/Israel.svg";
import uzb from "../../app/assets/Seller/Uzb.svg";
import turkey from "../../app/assets/Seller/Turkey.svg";
import germany from "../../app/assets/Seller/Germany.svg";
import france from "../../app/assets/Seller/France.svg";
import italy from "../../app/assets/Seller/Italy.svg";
import "./style.css";
export const CardSeller = ({ status }: { status?: string }) => {
    const values = [1, 3, 6]; // Допустимые значения
    const [value, setValue] = useState(values[0]); // Начальное значение
  
    const handleChange = (e:any) => {
      const inputValue = parseInt(e.target.value, 10);
  
      // Найти ближайшее допустимое значение
      const closestValue = values.reduce((prev, curr) => 
        Math.abs(curr - inputValue) < Math.abs(prev - inputValue) ? curr : prev
      );
  
      setValue(closestValue);
    };
  const [selectedValue, setSelectedValue] = useState<string>("20");

  // Обработчик изменения значения
  const handleChangeSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedValue(event.target.value); // Обновляем состояние
  };
  const titleClass =
    status === "Seller"
      ? s.sellerTitleBlock
      : status === "SellerPro"
      ? s.sellerProTitleBlock
      : s.titleBlock;

  const titleText =
    status === "Seller"
      ? "SELLER"
      : status === "SellerPro"
      ? "SELLER"
      : "USER";
  //   const elements = document.querySelectorAll(
  //     'input[type="range"].slider-progress',
  //   ) as NodeListOf<HTMLInputElement>;
  //   elements.forEach((e) => {
  //     e.style.setProperty("--value", e.value);
  //     e.style.setProperty("--min", e.min === "" ? "0" : e.min);
  //     e.style.setProperty("--max", e.max === "" ? "100" : e.max);
  //     e.addEventListener("input", () =>
  //       e.style.setProperty("--value", e.value),
  //     );
  //   });
  const priceMap: { [key: string]: { [key: number]: number } } = {
    "20": {
      1: 4000,
      3: 10200,
      6: 18000,
    },
    "30": {
      1: 6000,
      3: 15300,
      6: 27000,
    },
    "50": {
      1: 10000,
      3: 25500,
      6: 45000,
    },
    "100": {
      1: 20000,
      3: 51000,
      6: 90000,
    },
    "200": {
      1: 40000,
      3: 102000,
      6: 180000,
    },
    "300": {
      1: 60000,
      3: 153000,
      6: 270000,
    },
    "500": {
      1: 100000,
      3: 255000,
      6: 450000,
    },
    // Добавьте остальные значения по мере необходимости
  };

  // Функция для получения цены из priceMap
  const calculatePrice = (): number => {
    return priceMap[selectedValue]?.[value] || 0; // Если комбинация отсутствует, вернуть 0
  };

  return (
    <div className={s.wrapper}>
      <section className={titleClass}>
        <h1 className={s.title}>{titleText}</h1>
        {status === "Seller" ? (
          <span className={s.subtitle}>BASE</span>
        ) : status === "SellerPro" ? (
          <span className={s.subtitle}>PRO</span>
        ) : (
          ""
        )}
      </section>
      <div className={s.container}>
        <main>
          {status === "Seller" ? (
            <div className={s.availableContainer}>
              <span className={s.language}>Достпуные языки:</span>
              <span className={s.image}>
                <img src={rus} alt='rus' />
                <img src={uk} alt='Uk' />
                <img src={spain} alt='Spain' />
                <img src={UAE} alt='UAE' />
              </span>
            </div>
          ) : status === "SellerPro" ? (
            <div className={s.availableContainer}>
              <span className={s.language}>Достпуные языки:</span>
              <span className={s.image}>
                <img src={rus} alt='rus' />
                <img src={uk} alt='Uk' />
                <img src={spain} alt='Spain' />
                <img src={UAE} alt='UAE' />
                <img src={israel} alt='rus' />
                <img src={uzb} alt='Uk' />
                <img src={turkey} alt='Spain' />
                <img src={germany} alt='UAE' />
                <img src={france} alt='UAE' />
                <img src={italy} alt='UAE' />
              </span>
            </div>
          ) : (
            <span className={s.language}>Нет доступных языков</span>
          )}
          {status === "Seller" || status === "SellerPro" ? (
            <div className={s.bottomContainer}>
              <span className={s.text}>
                Объем загружаемых данных на сайт
              </span>
              <select
                className={s.select}
                value={selectedValue}
                onChange={handleChangeSelect}>
                <option value='20'>20ГБ</option>
                <option value='30'>30ГБ</option>
                <option value='50'>50ГБ</option>
                <option value='100'>100ГБ</option>
                <option value='200'>200ГБ</option>
                <option value='300'>300ГБ</option>
                <option value='500'>500ГБ</option>
              </select>
              <div className='range-container'>
                <label htmlFor='range' className={s.rangeLabel}>
                  Количество месяцев: {value}
                </label>
                <input
                  value={value} // Привязка значения к состоянию
                  type='range'
                  defaultValue={1}
                  min='0'
                  max='6'
                  step={3}
                  id='range'
                  className='styledSlider slider-progress'
                  list='markers'
                  onChange={handleChange} // Обработчик изменения значения
                />
                <datalist id='markers'>
                  <option value='1'></option>
                  <option value='3'></option>
                  <option value='6'></option>
                </datalist>
              </div>
              <button className={s.button}>
                {calculatePrice()} руб
              </button>
            </div>
          ) : (
            <button className={s.button}>У вас уже есть</button>
          )}
        </main>
      </div>
    </div>
  );
};
