import { useDispatch, useSelector } from "app/service/hooks/hooks";
import { setActiveFilter } from "app/service/lessons/lessonsSlice";
import styles from "./style.module.scss";
export const CustomFilter = ({ threeTab }: { threeTab: boolean }) => {
  const { activeFilter, usersProfiles } = useSelector(
    (store) => store.lessons,
  );
  const dispatch = useDispatch();
  const toggleFilter = (option: "popularity" | "date" | "topic") => {
    dispatch(setActiveFilter(option));
  };
  return (
    <div className={styles.wrapperCustomSelect}>
      <button
        className={`${styles.custom_select} ${
          activeFilter === "popularity" ? styles.active : ""
        }`}
        onClick={() => toggleFilter("popularity")}>
        По популярности
      </button>
      <button
        className={`${styles.custom_select} ${
          activeFilter === "date" ? styles.active : ""
        }`}
        onClick={() => toggleFilter("date")}>
        По дате публикации
      </button>
      {threeTab && (
        <button
          className={`${styles.custom_select} ${
            activeFilter === "topic" ? styles.active : ""
          }`}
          onClick={() => toggleFilter("topic")}>
          По теме урока
        </button>
      )}
    </div>
  );
};
