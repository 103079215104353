import React, { useRef } from "react"
import s from "./MainContent.module.scss"
import { HomeNavigation } from "./Navigation/HomeNavigation"
import { MainBlock } from "./Main/MainBlock"
import { AdBlock } from "./Ad/AdBlock"
import { useSelector } from "app/service/hooks/hooks"
import { SelectCountry } from "components/CreateLessonModal/ui/selectCountry/selectCountry"
export const MainContent = () => {
	const registrationRef = useRef(null)
	const settingProfileRef = useRef(null)
	const selectTutorialsRef = useRef(null)
	const paymentRef = useRef(null)
	const loadingTutorialsRef = useRef(null)
	const socialRef = useRef(null)
	const reviewsRef = useRef(null)
	const supportsRef = useRef(null)
	const drmRef = useRef(null)
	const watermarkRef = useRef(null)
	const accessControlRef = useRef(null)
	const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
		if (ref.current) {
			const yOffset = -200
			const yPosition =
				ref.current.getBoundingClientRect().top +
				window.pageYOffset +
				yOffset
			window.scrollTo({ top: yPosition, behavior: "smooth" })
		}
	}
	const { activeTab } = useSelector((state) => state.home)
	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				<nav className={activeTab === "manual" ? s.manual : ""}>
					<HomeNavigation
						scrollToSection={scrollToSection}
						registrationRef={registrationRef}
						settingProfileRef={settingProfileRef}
						selectTutorialsRef={selectTutorialsRef}
						paymentRef={paymentRef}
						loadingTutorialsRef={loadingTutorialsRef}
						socialRef={socialRef}
						reviewsRef={reviewsRef}
						supportsRef={supportsRef}
						drmRef={drmRef}
						watermarkRef={watermarkRef}
						accessControlRef={accessControlRef}
					/>
				</nav>
				<main>
					<MainBlock
						registrationRef={registrationRef}
						settingProfileRef={settingProfileRef}
						selectTutorialsRef={selectTutorialsRef}
						paymentRef={paymentRef}
						loadingTutorialsRef={loadingTutorialsRef}
						socialRef={socialRef}
						reviewsRef={reviewsRef}
						supportsRef={supportsRef}
						drmRef={drmRef}
						watermarkRef={watermarkRef}
						accessControlRef={accessControlRef}
					/>
				</main>
				<aside>
					<AdBlock />
					<AdBlock />
					<AdBlock />
				</aside>
			</div>
		</div>
	)
}
