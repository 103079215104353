import React, { useState } from "react";
import s from "./supportModal.module.scss";
import close from "../../../../../../app/assets/home/closeModal.svg";
import { useForm } from "react-hook-form";
import loadImage from "../../../../../../app/assets/home/loadImage.svg";
export const SupportModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data: any) => {
    console.log(data);
    reset();
  };
  const [, setFileName] = useState("");

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleFileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <section className={s.topBlock}>
          <h1>Создать обращение</h1>
          <img src={close} alt='closeIcon' onClick={onClose} />
        </section>
        <div className={s.formContainer}>
          <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
            <input
              type='text'
              className={s.input}
              placeholder='Введите имя*'
              {...register("firstName", {
                required: "Имя обязательно",
              })}
            />
            {/* {errors.firstName && <p className={s.error}>hi</p>} */}
            <input
              type='text'
              className={s.input}
              placeholder='Введите Фамилию*'
              {...register("lastName", {
                required: "Фамилия обязательна",
              })}
            />
            {/* {errors.lastName && <p className={s.error}>132</p>} */}
            <input
              type='tel'
              className={s.input}
              placeholder='Введите Номер телефона*'
              {...register("phone", {
                required: "Номер телефона обязателен",
                pattern: {
                  value: /^[0-9]{10,15}$/,
                  message: "Введите корректный номер телефона",
                },
              })}
            />
            {/* {errors.phone && <p className={s.error}>123</p>} */}
            <textarea
              className={s.textarea}
              placeholder='Текст обращения'
              {...register("message", {
                required: "Текст обращения обязателен",
              })}
            />
            {/* {errors.message && <p className={s.error}>123</p>} */}
            <input
              type='file'
              id='fileInput'
              className={s.hiddenFileInput}
              onChange={handleFileChange}
            />

            <div
              className={s.customFileInput}
              onClick={handleFileClick}>
              {/* {fileName
                ? fileName
                : "Перетащите файл или кликните для выбора"} */}
              <img src={loadImage} alt='' />
              <span className={s.text}>
                Перетащите файлы или нажмите кнопку ниже,
                <br /> чтобы выбрать их на устройстве
              </span>
              <span className={s.file}>Выбрать файл</span>
            </div>
            <button type='submit' className={s.submitButton}>
              Далее
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
