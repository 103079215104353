import React, { useState } from "react";
import s from "./stepTwo.module.scss";
import loadImage from "../../../../app/assets/home/loadImage.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "app/service/hooks/hooks";
import { setVideoDuration } from "app/service/createLesson/CreateLessonSlice";

export const StepTwo = () => {
  const [fileName, setFileName] = useState("");
  const { videoDuration } = useSelector(
    (state) => state.createLesson,
  );
  const dispatch = useDispatch();
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    console.log(file)
    setFileName(file.name.split(".mp4").join(""));
    if (file && file.type.startsWith("video/")) {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      const fileURL = URL.createObjectURL(file);
      videoElement.src = fileURL;

      videoElement.onloadedmetadata = () => {
        if (videoElement.duration < 360) {
          console.log(videoElement.duration);
          return toast.error("Видео должно быть не короче 15 минут");
        } else if (videoElement.duration > 3600) {
          return toast.error(
            "Видео должно быть не дольше одного часа",
          );
        } else {
          dispatch(setVideoDuration(videoElement.duration));
        }
        console.log(videoElement.duration);
        console.log(videoElement);
        URL.revokeObjectURL(fileURL);
      };

      videoElement.onerror = () => {
        console.error("Невозможно загрузить метаданные видео.");
      };
    } else {
      toast.error("Пожалуйста, выберите видеофайл,формата .mp4");
    }
  };

  const handleFileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <span className={s.text}>
          Чтобы создать красивую укладку волос феном и не повредить
          локоны, специалисты советуют придерживаться простых правил:
          <br /> сначала просушите волосы естественным путем – в
          течение 10-15 минут. Так вы подготовите пряди к воздействию
          горячего воздуха.
        </span>
        <input
          type='file'
          id='fileInput'
          className={s.hiddenFileInput}
          onChange={handleFileChange}
        />
        {!videoDuration ? (
          <div
            className={s.customFileInput}
            onClick={handleFileClick}>
            {/* {fileName
                ? fileName
                : "Перетащите файл или кликните для выбора"} */}
            <img src={loadImage} alt='' />
            <span className={s.text}>
              Перетащите файлы или нажмите кнопку ниже,
              <br /> чтобы выбрать их на устройстве
            </span>
            <span className={s.file}>Выбрать файл</span>
          </div>
        ) : (
          <div className={s.videoSceleton}>
            <span className={s.nameVideo}>
              {fileName} успешно загружено!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
