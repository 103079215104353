import { useDispatch, useSelector } from "app/service/hooks/hooks";
import React, { useEffect, useRef, useState } from "react";
import s from "./style.module.scss";

import { Lesson } from "pages/Lessons/lesson";
import { getDataUserProfile } from "app/api/api";
import { LessonForProfile } from "components/LessonsUi/LessonForProfile/LessonForProfile";
import { useLocation } from "react-router";
import { LessonSkeleton } from "components/LessonsUi/LessonSkeleton/LessonSkeleton";
import { getMyLessons, getUsersLessons } from "app/api/apiLessons";
import {
  appendMyLessons,
  appendUserLesson,
} from "app/service/lessons/lessonsSlice";
import { profile } from "console";
export const UserProfileLessons = () => {
  const { userLesson, profiles, myLessons } = useSelector(
    (store) => store.lessons,
  );
  const { profileData } = useSelector((store) => store.profileCard);
  console.log(profileData);
  const { userData } = useSelector((store) => store.user);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const [offset, setOffset] = useState(16);
  const [loadingMore, setLoadingMore] = useState(false);
  useEffect(() => {
    if (userData?.user_id) {
      setCurrentId(userData.user_id);
    }
  }, [userData?.user_id]);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const loadMoreLessons = async () => {
      if (!loadingMore) {
        setLoadingMore(true);

        if (
          myLessons &&
          myLessons.next &&
          myLessons.count &&
          myLessons.count > offset
        ) {
          await getMyLessons(dispatch, offset, 16);
          dispatch(appendMyLessons(myLessons));
          setOffset((prevOffset) => prevOffset + 16);
        } else if (
          userLesson &&
          userLesson.next &&
          userLesson.count &&
          userLesson?.count > offset
        ) {
          await getUsersLessons(
            dispatch,
            profileData?.user_id.toString()!,
            offset,
            16,
          );
          dispatch(appendUserLesson(userLesson));
          setOffset((prevOffset) => prevOffset + 16);
        }

        setLoadingMore(false);
      }
    };

    // Обработчик для IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loadingMore) {
          loadMoreLessons();
        }
      },
      { threshold: 1.0 },
    );

    // Наблюдение за рефом
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    // Очистка наблюдателя
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [
    dispatch,
    offset,
    loadingMore,
    myLessons,
    myLessons?.next,
    userLesson,
    userLesson?.next,
    profileData?.user_id,
  ]);
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        {pathname === "/profile" ? (
          myLessons?.count! > 0 ? (
            myLessons?.results.map((lesson, index) => (
              <LessonForProfile
                currentId={userData?.user_id!}
                lessonData={lesson}
                key={index}
                profileData={profiles[userData?.user_id!] || null}
              />
            ))
          ) : (
            <LessonSkeleton text='Уроков не найдено' />
          )
        ) : userLesson?.count! > 0 ? (
          userLesson?.results.map((lesson, index) => (
            <LessonForProfile
              currentId={currentId!}
              lessonData={lesson}
              key={index}
              profileData={profiles[lesson?.user_id!] || null}
            />
          ))
        ) : (
          <LessonSkeleton text='Уроков не найдено' />
        )}
      </div>
      <div ref={loaderRef}></div>
    </div>
  );
};
