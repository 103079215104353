import React, { useState } from "react";
import s from "./stepOne.module.scss";
import man from "../../../../app/assets/createLessonModal/man.svg";
import woman from "../../../../app/assets/createLessonModal/woman.svg";
import { useDispatch, useSelector } from "app/service/hooks/hooks";
import {
  setDescriptionLesson,
  setNameLesson,
  setPriceLesson,
} from "app/service/createLesson/CreateLessonSlice";
export const StepOne = () => {
  const { nameLesson, priceLesson, descriptionLesson } = useSelector(
    (state) => state.createLesson,
  );
  const dispatch = useDispatch();
  const handleChangeName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setNameLesson(e.target.value));
  };
  const handleChangeDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    dispatch(setDescriptionLesson(e.target.value));
  };
  const handleChangePrice = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;

    const numericValue = value ? parseFloat(value) : 0;

    if (!isNaN(numericValue)) {
      dispatch(setPriceLesson(numericValue));
    }
  };

  const [active, setActive] = useState<"man" | "woman" | null>("man");

  return (
    <main className={s.mainBlock}>
      <div className={s.inputNameVideoContainer}>
        <label htmlFor='name' className={s.labelNameVideo}>
          Название урока
        </label>
        <input
          id='name'
          value={nameLesson ? nameLesson : ""}
          className={s.inputNameVideo}
          onChange={handleChangeName}
          type='text'
          max={100}
          placeholder='Укладка волос в необычном стиле'
        />
      </div>
      <div className={s.descriptionVideoContainer}>
        <label htmlFor='description' className={s.labelNameVideo}>
          Описание урока
        </label>
        <textarea
          value={descriptionLesson ? descriptionLesson : ""}
          id='description'
          maxLength={100}
          onChange={handleChangeDescription}
          className={s.textarea}
          placeholder='Чтобы создать красивую укладку волос феном и не повредить локоны, специалисты советуют придерживаться простых правил:
                сначала просушите волосы естественным путем – в течение 10-15 минут. Так вы подготовите пряди к воздействию '
        />
      </div>
      <div className={s.voiceTranslateContanier}>
        <div className={s.leftSide}>
          <section>
            <h1 className={s.title}>
              Параметры голосового перевода урока
            </h1>
          </section>
          <span className={s.text}>
            Выберите голос который будет вас озвучивать:
          </span>
        </div>
        <div className={s.select}>
          <img
            src={man}
            alt='man'
            className={active === "man" ? s.active : s.default}
            onClick={() => setActive("man")}
          />
          <span className={s.line}></span>
          <img
            src={woman}
            alt='woman'
            className={active === "woman" ? s.active : s.default}
            onClick={() => setActive("woman")}
          />
        </div>
      </div>
      {/* <div className={s.selectLanguage}>
        <section>
          <h1 className={s.selectTitle}>
            С какого языка переводится урок
          </h1>
        </section>
        <CustomSelectCreateLessonModal />
        <section>
          <h2 className={s.secondTitle}>
            Языки, на которые будет переводиться урок
          </h2>
        </section>
        <SelectCountry />
      </div> */}
      <div className={s.setPriceContainer}>
        <section>
          <h1 className={s.setPriceTitle}>
            Установите стоимость урока
          </h1>
        </section>
        <div className={s.setPrice}>
          <input
            value={priceLesson ? priceLesson : ""}
            type='number'
            className={s.setPriceInput}
            onChange={handleChangePrice}
          />
          <span className={s.line}></span>
          <span className={s.value}>Руб.</span>
        </div>
      </div>
    </main>
  );
};
