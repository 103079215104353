import React, { useState } from "react";
import s from "./stepThree.module.scss";
import loadImage from "../../../../app/assets/home/loadImage.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "app/service/hooks/hooks";
import { setImageSrc } from "app/service/createLesson/CreateLessonSlice";

export const StepThree = () => {
  // const [imageSrc, setImageSrc] = useState<string | null>(null);
  const { imageSrc } = useSelector((state) => state.createLesson);
  const dispatch = useDispatch();
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && typeof e.target.result === "string") {
          dispatch(setImageSrc(e.target.result));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <span className={s.text}>
          Чтобы создать красивую укладку волос феном и не повредить
          локоны, специалисты советуют придерживаться простых правил:
          <br /> сначала просушите волосы естественным путем – в
          течение 10-15 минут. Так вы подготовите пряди к воздействию
          горячего воздуха.
        </span>
        <input
          type='file'
          id='fileInput'
          accept='image/*'
          className={s.hiddenFileInput}
          onChange={handleFileChange}
        />
        {!imageSrc ? (
          <div
            className={s.customFileInput}
            onClick={handleFileClick}>
            {/* {fileName
                ? fileName
                : "Перетащите файл или кликните для выбора"} */}
            <img src={loadImage} alt='' />
            <span className={s.text}>
              Перетащите файлы или нажмите кнопку ниже,
              <br /> чтобы выбрать их на устройстве
            </span>
            <span className={s.file}>Выбрать файл</span>
          </div>
        ) : (
          <div className={s.videoSceleton}>
            <span className={s.nameVideo}>
              {imageSrc && (
                <img
                  src={imageSrc}
                  alt='Uploaded Preview'
                  className={s.videoImage}
                />
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
