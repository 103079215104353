import React, { useState } from "react"
import s from "./support.module.scss"
import { SupportModal } from "./supportModal/SupportModal"
import { ChatModal } from "./ChatModal/ChatModal"
import { AdaptiveCardSupport } from "./AdaptiveCardSupport/AdaptiveCardSupport"
export const Support = () => {
	const [openCreateModal, setOpenCreateModal] = useState(false)
	const [openChat, setOpenChat] = useState(false)
	const onClose = () => {
		openCreateModal && setOpenCreateModal(false)
		openChat && setOpenChat(false)
	}
	const tableData = [
		{
			id: 1,
			status: "Открыт",
			name: "Пример элемента 1",
			createdAt: "2024-11-01",
			updatedAt: "2024-11-05",
		},
		{
			id: 2,
			status: "Закрыт",
			name: "Пример элемента 2",
			createdAt: "2024-10-25",
			updatedAt: "2024-10-30",
		},
		{
			id: 3,
			status: "Закрыт",
			name: "Не могу загрузить видео на платформу ,после всех регистрац...",
			createdAt: "2024-09-20",
			updatedAt: "2024-10-28",
		},
		{
			id: 4,
			status: "Закрыт",
			name: "Не могу вывести деньги с баланса",
			createdAt: "2024-08-15",
			updatedAt: "2024-09-15",
		},
		{
			id: 123456789,
			status: "Открыт",
			name: "Пример элемента 5",
			createdAt: "2024-07-10",
			updatedAt: "2024-08-01",
		},
	]
	return (
		<div className={s.wrapper}>
			<section className={s.topBlock}>
				<h1 className={s.helpTitle}>Поддержка</h1>
				<span className={s.reference}>Справка</span>
			</section>
			<div className={s.supportNavigation}>
				<div className={s.leftButtons}>
					<button className={s.buttonActive}>Все</button>
					<button className={s.button}>Открытые</button>
					<button className={s.button}>Закрытые</button>
				</div>
				<button
					className={s.create}
					onClick={() => setOpenCreateModal(true)}>
					Создать обращение
				</button>
			</div>
			<table className={s.table}>
				<thead>
					<tr className={s.tableHeader}>
						<th className={s.headerElement}>Номер</th>
						<th className={s.headerElement}>Статус</th>
						<th className={s.headerElement}>Наименование</th>
						<th className={s.headerElement}>Дата создания</th>
						<th className={s.headerElement}>Дата обновления</th>
					</tr>
				</thead>
				<tbody>
					{tableData.map((row) => (
						<tr key={row.id} className={s.row}>
							<td
								className={s.cell}
								onClick={() => setOpenChat(true)}>
								{row.id}
							</td>
							<td className={s.cell}>{row.status}</td>
							<td className={s.cell}>{row.name}</td>
							<td className={s.cell}>{row.createdAt}</td>
							<td className={s.cell}>{row.updatedAt}</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className={s.adaptiveSupport}>
				{tableData.map((el) => (
					<div onClick={() => setOpenChat(true)}>
						<AdaptiveCardSupport />
					</div>
				))}
			</div>

			{openCreateModal && (
				<div className={s.blurBg}>
					<SupportModal onClose={onClose} />
				</div>
			)}
			{openChat && (
				<div className={s.blurBg}>
					<ChatModal onClose={onClose} />
				</div>
			)}
		</div>
	)
}
