import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { IGetUserData, ISearchData, IUser } from "../../types/type"
import type { RootState } from "../store"

interface IUserState {
	user: IUser | null
	isAuth: boolean
	userData: IGetUserData | null
	userSearchData: ISearchData[] | null
}

const initialState: IUserState = {
	user: null,
	isAuth: localStorage.getItem("isAuth") === "true",
	userData: null,
	userSearchData: null,
}

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state, action: PayloadAction<IUser>) => {
			state.user = action.payload
			state.isAuth = true
			localStorage.setItem("isAuth", "true")
		},
		logout: (state) => {
			// state.isAuth = false
			// state.user = null
			// state.userData = null
			// localStorage.setItem("isAuth", "false")
			// localStorage.removeItem("userData")
			// localStorage.removeItem("email")
		},
		setIsAuth: (state, action: PayloadAction<boolean>) => {
			state.isAuth = action.payload
		},
		setUserData: (state, action: PayloadAction<IGetUserData>) => {
			state.userData = action.payload
			localStorage.setItem("userData", JSON.stringify(action.payload)) // Сохраняем данные в localStorage
		},
		setUserSearchData: (
			state,
			action: PayloadAction<ISearchData[]>,
		) => {
			state.userSearchData = action.payload
		},
	},
})

export const {
	login,
	logout,
	setUserData,
	setUserSearchData,
	setIsAuth,
} = userSlice.actions

export const selectCount = (state: RootState) => state.user

export default userSlice.reducer
