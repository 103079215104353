import s from "./style.module.scss";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "app/service/hooks/hooks";
import { UserProfileFollowers } from "../UserProfile/userProfileFollowers/UserProfileFollowers";

import { UserProfileFollowing } from "../UserProfile/userProfileFollowing/UserProfileFollowing";
import {
  getFavouriteLessonsList,
  getFollowers,
  getFollowing,
  getMyLessons,
  getUsersLessons,
  getUsersProfileList,
} from "app/api/apiLessons";
import { UserProfileLessons } from "../UserProfile/userLessons/UserProfileLessons";
import { UserFavouriteLessons } from "../UserProfile/userFavouriteLessons/UserProfileFavouriteLessons";
import { ILesson } from "app/types/type";
import { CreateLessonModal } from "components/CreateLessonModal/CreateLessonModal";

export const NavigationProfile = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [activeButton, setActiveButton] = useState<string | null>();

  const [activeButtonDesktop, setActiveButtonDesktop] = useState<
    number | null
  >(null);

  const { userData } = useSelector((store) => store.user);
  const { myLessons, favouriteLessonsList } = useSelector(
    (store) => store.lessons,
  );

  const { myProfileCounter, counter } = useSelector(
    (store) => store.profileCard,
  );
  const dispatch = useDispatch();

  const buttonsTop = [
    {
      text: "Мои уроки",
      count: counter ? counter?.map((el) => el.count_lessons) : "",
    },
    {
      text: "Доступ к урокам",
      count: myProfileCounter?.purchase_count
        ? myProfileCounter?.purchase_count
        : "",
    },
    {
      text: "Избранные уроки",
      count: myProfileCounter?.favorite_count
        ? myProfileCounter.favorite_count
        : "",
    },
    { text: "Мои события", count: "" },
    { text: "Мое портфолио" },
    {
      text: "Подписчики",
      count: userData?.followers_count ? userData.followers_count : 0,
    },
    {
      text: "Подписки",
      count: userData?.following_count ? userData.following_count : 0,
    },
    { text: "Финансы" },
  ];

  const buttons = [
    {
      id: "lessons",
      label: "Мои уроки",
      count: myLessons?.count ? myLessons?.count : "",
    },
    {
      id: "access",
      label: "Доступ к урокам",
      count: myProfileCounter?.purchase_count
        ? myProfileCounter?.purchase_count
        : "",
    },
    {
      id: "favourites",
      label: "Избранные уроки",
      count: myProfileCounter?.favorite_count
        ? myProfileCounter.favorite_count
        : "",
    },
    { id: "events", label: "Мои события", count: "" },
    { id: "portfolio", label: "Мое портфолио" },
    {
      id: "subscribers",
      label: "Подписчики",
      count: userData?.followers_count ? userData.followers_count : 0,
    },
    {
      id: "subscriptions",
      label: "Подписки",
      count: userData?.following_count ? userData.following_count : 0,
    },
    { id: "finances", label: "Финансы" },
  ];
  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartPos(e.pageX - containerRef.current!.offsetLeft);
    setScrollLeft(containerRef.current!.scrollLeft);
  };

  // Окончание перетаскивания
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handleTouchStart = (e: React.TouchEvent) => {
    setIsDragging(true);
    setStartPos(
      e.touches[0].pageX - containerRef.current!.offsetLeft,
    );
    setScrollLeft(containerRef.current!.scrollLeft);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current!.offsetLeft;
    const walk = (x - startPos) * 2;
    containerRef.current!.scrollLeft = scrollLeft - walk;
  };

  // Перемещение мышью
  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();

    const x = e.pageX - containerRef.current!.offsetLeft;
    const walk = (x - startPos) * 2;
    containerRef.current!.scrollLeft = scrollLeft - walk;
  };

  const handleButtonClick = async (id: string) => {
    setActiveButton(id);
    setActiveButtonDesktop(null);
    if (activeButton === id) return;

    if (id === "subscribers") {
      getFollowers(dispatch, userData?.user_id.toString()!);
    }
    if (id === "subscriptions") {
      getFollowing(dispatch, userData?.user_id.toString()!);
    }
    if (id === "lessons") {
      getMyLessons(dispatch);
    }
  };

  const handleButtonClickDesktop = async (index: number) => {
    setActiveButtonDesktop(index);
    setActiveButton(null);
    if (activeButtonDesktop === index) return;

    if (buttons[index].label === "Подписчики") {
      await getFollowers(dispatch, userData?.user_id.toString()!);
    }
    if (buttons[index].label === "Подписки") {
      await getFollowing(dispatch, userData?.user_id.toString()!);
    }
    if (buttons[index].label === "Мои уроки") {
      await getMyLessons(dispatch);
    }
  };

  const buttonClass = (index: number) =>
    activeButtonDesktop === index
      ? `${s.button_main_profile} ${s.active}`
      : s.button_main_profile;

  return (
    <div className={s.buttons_page_profile}>
      <div className={s.buttons_containers_profile_top}>
        {buttonsTop.map((button, index) => (
          <button
            key={index}
            className={buttonClass(index)}
            onClick={() => handleButtonClickDesktop(index)}>
            <span className={s.button_main_profile_text}>
              {button.text}
              {button.count && (
                <sup className={s.sup}>({button.count})</sup>
              )}
            </span>
          </button>
        ))}
      </div>

      <div
        className={s.buttonsContainer}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onMouseMove={handleMouseMove}
        onTouchMove={handleTouchMove}>
        <div className={s.buttons}>
          {buttons.map((button) => (
            <button
              key={button.id}
              className={`${s.button_main_profile} ${
                activeButton === button.id ? s.active : ""
              }`}
              onClick={() => handleButtonClick(button.id)}>
              <span className={s.button_main_profile_text}>
                {button.label}
                {button.count && (
                  <sup className={s.sup}>({button.count})</sup>
                )}
              </span>
            </button>
          ))}
        </div>
      </div>

      {(activeButton === "subscribers" ||
        activeButtonDesktop === 5) && <UserProfileFollowers />}
      {(activeButton === "subscriptions" ||
        activeButtonDesktop === 6) && <UserProfileFollowing />}
      {(activeButton === "lessons" || activeButtonDesktop === 0) && (
        <UserProfileLessons />
      )}

      {activeButton === "favourites" ||
        (activeButtonDesktop === 2 && <UserFavouriteLessons />)}
    </div>
  );
};
