// burgerProfilesSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IBurgerState, IGetUserData } from "app/types/type"

const initialState: IBurgerState = {
	profiles: JSON.parse(
		localStorage.getItem("profiles") || "[]",
	) as IGetUserData[],
	activeProfile: localStorage.getItem("activeAcc")
		? JSON.parse(localStorage.getItem("activeAcc") as string)
		: null,
}

export const burgerProfilesSlice = createSlice({
	name: "burgerProfiles",
	initialState,
	reducers: {
		addProfile: (state, action: PayloadAction<IGetUserData>) => {
			const profileExists = state.profiles.some(
				(profile) => profile.user_id === action.payload.user_id,
			)

			if (!profileExists) {
				// Если профиль не найден, добавляем его
				state.profiles.push(action.payload)
				// Обновляем localStorage
				localStorage.setItem(
					"profiles",
					JSON.stringify(state.profiles),
				)
			}
		},
		setActiveProfile: (state, action: PayloadAction<number>) => {
			state.activeProfile = action.payload
			localStorage.setItem("activeAcc", action.payload.toString())
		},
		updateProfile: (state, action: PayloadAction<IGetUserData>) => {
			const index = state.profiles.findIndex(
				(profile) => profile.user_id === action.payload.user_id,
			)
			if (index !== -1) {
				state.profiles[index] = action.payload
			}
		},
		removeProfile: (state, action: PayloadAction<number>) => {
			state.profiles = state.profiles.filter(
				(profile) => profile.user_id !== action.payload,
			)
			console.log(state.profiles)
			localStorage.setItem("profiles", JSON.stringify(state.profiles))
		},
		clearProfiles: (state) => {
			state.profiles = []
			state.activeProfile = null
		},
	},
})

export const {
	addProfile,
	setActiveProfile,
	updateProfile,
	clearProfiles,
	removeProfile,
} = burgerProfilesSlice.actions
export default burgerProfilesSlice.reducer
