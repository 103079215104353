import React from "react"
import s from "./adaptiveCardSupport.module.scss"
export const AdaptiveCardSupport = () => {
	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				<div className={s.topBlock}>
					<span className={s.createDate}>C 14.11.2024</span>
					<p className={s.id}>
						ID: <span>123456789</span>
					</p>
				</div>
				<div className={s.mainBlock}>
					<section>
						<h1 className={s.title}>Наименование</h1>
					</section>
					<span className={s.description}>
						Не могу вывести деньги с баланса платформы Не могу вывести
						деньги с баланса платф...
					</span>
				</div>
				<div className={s.bottomBlock}>
					<span className={s.updateDate}>Обновлено: 14.11.2024 </span>
					<span className={s.status}>Открыт</span>
				</div>
			</div>
		</div>
	)
}
