import React, { useState } from "react";
import s from "./createLessonModal.module.scss";
import close from "../../app/assets/home/closeModal.svg";
import { StepsCircles } from "./ui/stepsCircles/stepsCircles";
import { StepOne } from "./steps/one/StepOne";
import { StepTwo } from "./steps/two/stepTwo";
import { StepThree } from "./steps/three/stepThree";
import { StepFour } from "./steps/four/StepFour";
import { useSelector } from "app/service/hooks/hooks";
export const CreateLessonModal = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const [steps, setSteps] = useState<1 | 2 | 3 | 4>(1);
  const {
    nameLesson,
    priceLesson,
    descriptionLesson,
    videoDuration,
    imageSrc,
  } = useSelector((state) => state.createLesson);
  const checkValue = () => {
    if (steps === 1) {
      if (nameLesson && priceLesson && descriptionLesson !== null) {
        return true;
      } else {
        return false;
      }
    } else if (steps === 2) {
      if (videoDuration) {
        return true;
      } else {
        return false;
      }
    } else if (steps === 3) {
      if (imageSrc) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <section className={s.topBlock}>
          {steps === 1 && <h1>Заполните информацию об уроке</h1>}
          {steps === 2 && <h1>Загрузите видео</h1>}
          {steps === 3 && <h1>Загрузите обложку</h1>}
          {steps === 4 && <h1>Предпросмотр урока</h1>}
          <img src={close} alt='closeIcon' onClick={closeModal} />
        </section>
        <div className={s.circles}>
          <StepsCircles steps={steps} />
        </div>
        {steps === 1 && <StepOne />}
        {steps === 2 && <StepTwo />}
        {steps === 3 && <StepThree />}
        {steps === 4 && <StepFour />}

        <div
          className={
            steps > 1 ? s.buttonContainerJustify : s.buttonContainer
          }>
          {steps > 1 && (
            <button
              className={s.back}
              onClick={() =>
                setSteps((prevSteps) =>
                  prevSteps > 1
                    ? ((prevSteps - 1) as 1 | 2 | 3 | 4)
                    : 1,
                )
              }>
              {" "}
              Назад
            </button>
          )}
          {steps === 4 ? (
            <button className={s.next}>Опубликовать</button>
          ) : (
            <button
              className={checkValue() ? s.next : s.disabled}
              onClick={() =>
                checkValue()
                  ? setSteps((prevSteps) =>
                      prevSteps < 4
                        ? ((prevSteps + 1) as 1 | 2 | 3 | 4)
                        : 4,
                    )
                  : ""
              }>
              Далее
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
