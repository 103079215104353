import { FC, useEffect, useState } from "react"
import { User } from "./User"
import styles from "./Users.module.scss"
// Import image
import img from "../../app/assets/users/filter.svg"
import search from "../../app/assets/users/search.svg"
import close from "../../app/assets/users/closeModal.svg"
import { Link } from "react-router-dom"
import {
	getUsers,
	getUsersSearchByNameAndLocation,
	getUsersWithOutToken,
	getUsersWithOutVideo,
	getUsersWithOutVideoAndToken,
} from "app/api/apiUsers"

import { useDispatch, useSelector } from "app/service/hooks/hooks"
import {
	getUsersProfileList,
	getUsersProfileListWithOutToken,
} from "app/api/apiLessons"
import { IGetUsersInitialState } from "app/types/type"
import { Loading } from "components/Loading/Loading"
import { UserCardSmall } from "components/Profile/userCardSmall/UserCardSmall"

export const Users: FC = () => {
	const { isAuth, userData, userSearchData } = useSelector(
		(store) => store.user,
	)
	const { usersProfiles } = useSelector((store) => store.lessons)
	const { maxViewsUsers, withOutVideoUsers } = useSelector(
		(state) => state.users,
	)

	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()

	// Имя
	const [inputName, setInputName] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [isFirstNameSearchDone, setIsFirstNameSearchDone] =
		useState(false)

	const handleNameInputChange = (e: any) => {
		const value = e.target.value
		setInputName(value)

		const nameParts = value.split(" ")
		setFirstName(nameParts[0] || "")
		setLastName(nameParts[1] || "")
	}
	console.log(firstName, lastName)
	// const searchByNameWithFallback = async () => {
	//   setIsFirstNameSearchDone(false);
	//   if (firstName && lastName) {
	//     await getUsersSearchByName(dispatch, firstName, lastName);

	//     // Если данных нет, выполняем поэтапный fallback-поиск
	//     if (userSearchData?.length === 0) {
	//       // Поиск только по стране
	//       await getUsersSearchByName(dispatch, firstName);

	//       // Если снова данных нет, поиск по стране как городу
	//       if (userSearchData?.length === 0) {
	//         await getUsersSearchByName(dispatch, "", firstName);
	//       }
	//     }
	//   } else if (firstName) {
	//     // Если только страна указана
	//     await getUsersSearchByName(dispatch, firstName);

	//     if (userSearchData?.length === 0) {
	//       await getUsersSearchByName(dispatch, "", firstName);
	//     }
	//   } else if (lastName) {
	//     await getUsersSearchByName(dispatch, lastName, "");
	//     if (userSearchData?.length === 0) {
	//       await getUsersSearchByName(dispatch, "", lastName);
	//     }
	//   }
	//   setIsFirstNameSearchDone(true);
	// };

	// // useEffect для fallback-запросов
	// useEffect(() => {
	//   if (isFirstNameSearchDone && userSearchData?.length === 0) {
	//     if (firstName) {
	//       const newName = firstName;
	//       setLastName(newName);
	//       setFirstName("");
	//       getUsersSearchByName(dispatch, "", firstName);
	//     } else if (firstName && lastName) {
	//       getUsersSearchByName(dispatch, lastName, firstName);
	//     }
	//   }
	// }, [
	//   isFirstNameSearchDone,
	//   userSearchData,
	//   firstName,
	//   lastName,
	//   dispatch,
	// ]);

	// // Страна
	const [inputValue, setInputValue] = useState("")
	const [country, setCountry] = useState("")
	const [city, setCity] = useState("")
	// const [isCountrySearchDone, setIsCountrySearchDone] =
	//   useState(false);
	// // Страна
	// const searchByLocationWithFallback = async () => {
	//   setIsCountrySearchDone(false);
	//   if (!country && !city) {
	//     // Вызов функции для получения начального массива
	//     return null;
	//   }
	//   // Сначала выполняем поиск по стране и городу (если оба указаны)
	//   if (country && city) {
	//     await getUsersSearchByLocation(dispatch, country, city);

	//     // Если данных нет, выполняем поэтапный fallback-поиск
	//     if (userSearchData?.length === 0) {
	//       // Поиск только по стране
	//       await getUsersSearchByLocation(dispatch, country);

	//       // Если снова данных нет, поиск по стране как городу
	//       if (userSearchData?.length === 0) {
	//         await getUsersSearchByLocation(dispatch, "", country);
	//       }
	//     }
	//   } else if (country) {
	//     // Если только страна указана
	//     await getUsersSearchByLocation(dispatch, country);

	//     // Если данных нет, выполняем поиск по стране как городу
	//     if (userSearchData?.length === 0) {
	//       await getUsersSearchByLocation(dispatch, "", country);
	//     }
	//   } else if (city) {
	//     // Если указано только значение города
	//     await getUsersSearchByLocation(dispatch, "", city);
	//   }

	//   setIsCountrySearchDone(true);
	// };

	// // useEffect для обработки ситуации, когда первичный поиск по стране не дал результатов
	// useEffect(() => {
	//   if (isCountrySearchDone && userSearchData?.length === 0) {
	//     if (country) {
	//       const newCity = country;
	//       setCity(newCity);
	//       setCountry("");
	//       getUsersSearchByLocation(dispatch, "", newCity);
	//     }
	//   }
	// }, [isCountrySearchDone, userSearchData, country, city, dispatch]);

	const handleInputChange = (e: any) => {
		const value = e.target.value.replace(/\s+/g, ",")
		setInputValue(value)

		const locationParts = value.split(",")
		if (locationParts.length === 1) {
			setCountry(locationParts[0])
			setCity("")
		} else {
			setCountry(locationParts[0])
			setCity(locationParts[1] || "")
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			if (isAuth) {
				setLoading(true)
				await getUsers(dispatch)
				await getUsersWithOutVideo(dispatch)
				setLoading(false)
			} else {
				setLoading(true)
				await getUsersWithOutToken(dispatch)
				await getUsersWithOutVideoAndToken(dispatch)
				setLoading(false)
			}
		}
		fetchData()
	}, [dispatch, isAuth])

	useEffect(() => {
		const fetchProfiles = async () => {
			if (!loading && maxViewsUsers?.results) {
				const uniqueUserIds = new Set<number>()
				maxViewsUsers.results.forEach(
					(user: IGetUsersInitialState) => {
						uniqueUserIds.add(user?.user_id!)
					},
				)
				const userIdArray = Array.from(uniqueUserIds)

				if (userIdArray.length === 0) return
				const idsString = userIdArray.join(",")
				if (isAuth) {
					getUsersProfileList(dispatch, idsString)
				} else {
					getUsersProfileListWithOutToken(dispatch, idsString)
				}
			}
		}
		fetchProfiles()
	}, [maxViewsUsers, loading, dispatch])

	// Map для данных о просмотрах
	const viewsMap = maxViewsUsers?.results?.reduce((acc, item) => {
		acc[item.user_id] = {
			total_views: item.total_views,
			count_lessons: item.count_lessons,
		}
		return acc
	}, {} as Record<number, { total_views: number; count_lessons: number }>)

	const combinedUsers = [
		...(usersProfiles || []),
		...(withOutVideoUsers?.results || []),
	]

	const handleSearch = () => {
		// Выполнение запроса с переданными параметрами
		getUsersSearchByNameAndLocation(
			dispatch,
			country || undefined, // передаем country, если оно не пустое
			city || undefined, // передаем city, если оно не пустое
			firstName || undefined, // передаем firstName, если оно не пустое
			lastName || undefined, // передаем secondName, если оно не пустое
		)
	}
	const handleReturnDefault = () => {
		if (
			userSearchData &&
			userSearchData?.length !== combinedUsers.length
		) {
			getUsersSearchByNameAndLocation(
				dispatch,
				"", // передаем country, если оно не пустое
				"", // передаем city, если оно не пустое
				"", // передаем firstName, если оно не пустое
				"", // передаем secondName, если оно не пустое
			)

			setInputValue("")
			setCountry("")
			setCity("")
		} else {
			setInputValue("")
			setCountry("")
			setCity("")
		}
	}
	const handleReturnDefaultName = () => {
		if (
			userSearchData &&
			userSearchData?.length !== combinedUsers.length
		) {
			getUsersSearchByNameAndLocation(
				dispatch,
				"", // передаем country, если оно не пустое
				"", // передаем city, если оно не пустое
				"", // передаем firstName, если оно не пустое
				"", // передаем secondName, если оно не пустое
			)
			setInputName("")
			setFirstName("")
			setLastName("")
		} else {
			setInputName("")
			setFirstName("")
			setLastName("")
		}
	}
	console.log(inputValue)
	// const searchByName = async () => {
	//   await getUsersSearchByName(dispatch, firstName, lastName);
	// };
	// const searchByLocationWithFallback = async () => {
	//   await getUsersSearchByLocation(dispatch, country);
	//   console.log(userSearchData?.length);
	//   console.log(city, country);
	//   if (userSearchData?.length === 0 && city) {
	//     console.log(1);
	//     await getUsersSearchByLocation(dispatch, "", country); // Поиск только по городу
	//   }
	// };
	return (
		<div className={styles.container_users}>
			{!maxViewsUsers && !withOutVideoUsers && loading ? (
				<Loading />
			) : (
				<>
					<div className={styles.inputs_container}>
						<div className={styles.searchName}>
							<img className={styles.vol} src={img} alt="" />
							<input
								className={styles.inputName}
								placeholder="Поиск по имени"
								value={inputName}
								onChange={handleNameInputChange}
								type="text"
							/>
							<img
								className={styles.search_img}
								src={search}
								alt=""
								onClick={handleSearch}
							/>
							{inputName.length > 0 && (
								<img
									className={styles.closeIcon}
									src={close}
									alt="closeIcon"
									onClick={handleReturnDefaultName}
								/>
							)}
						</div>
						<div className={styles.searchLocation}>
							<img className={styles.vol} src={img} alt="" />
							<input
								className={styles.inputLocation}
								placeholder="Поиск по местоположению"
								value={inputValue}
								onChange={handleInputChange}
								type="text"
							/>
							<img
								className={styles.search_img}
								src={search}
								alt=""
								onClick={handleSearch}
							/>
							{inputValue.length > 0 && (
								<img
									className={styles.closeIcon}
									src={close}
									alt="closeIcon"
									onClick={handleReturnDefault}
								/>
							)}
						</div>
						<div className={styles.searchSmall}>
							<img className={styles.vol} src={img} alt="" />
							<input
								className={styles.inputLocation}
								placeholder="Поиск..."
								type="text"
							/>
							<img
								className={styles.search_img}
								src={search}
								alt=""
							/>
						</div>
					</div>
					<div className={styles.users_box}>
						{(userSearchData && userSearchData?.length > 0
							? userSearchData
							: combinedUsers
						).map((el, index) => {
							const userViewsData =
								viewsMap && viewsMap[el?.user_id]
									? viewsMap[el?.user_id]
									: { total_views: 0, count_lessons: 0 }

							return (
								<Link
									to={
										el.user_id === userData?.user_id
											? `/profile`
											: `/profile/${el.user_id}`
									}
									key={index}>
									<User
										userData={el}
										totalViews={userViewsData.total_views}
										countLessons={userViewsData.count_lessons}
									/>
								</Link>
							)
						})}
					</div>
					<div className={styles.users_boxSmallCard}>
						{(userSearchData && userSearchData?.length > 0
							? userSearchData
							: combinedUsers
						).map((el, index) => {
							const userViewsData =
								viewsMap && viewsMap[el?.user_id]
									? viewsMap[el?.user_id]
									: { total_views: 0, count_lessons: 0 }

							return (
								<Link
									to={
										el.user_id === userData?.user_id
											? `/profile`
											: `/profile/${el.user_id}`
									}
									key={index}>
									<UserCardSmall
										userData={el}
										totalViews={userViewsData.total_views}
										countLessons={userViewsData.count_lessons}
									/>
								</Link>
							)
						})}
					</div>
				</>
			)}
		</div>
	)
}
