import React from "react";
import s from "./seller.module.scss";
import { CardSeller } from "components/SellerUi/CardSeller";
export const Seller = () => {
  return (
    <div className={s.wrapper}>
      <CardSeller />
      <CardSeller status='Seller' />
      <CardSeller status='SellerPro' />
    </div>
  );
};
