import { FC, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./App.module.scss";
import { AuthForm } from "../components/AuthForm/AuthForm";
import { BottomBarPhone } from "../components/BottomBarPhone/BottomBarPhone";
import { Burger } from "../components/Burger/Burger";
import { Header } from "../components/Header/Header";
import { Loading } from "../components/Loading/Loading";
import AppRouter from "./router";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "./service/hooks/hooks";
import {
  setIsAuthOpen,
  setIsModalOpen,
} from "./service/navigationModals/NavigationModalsSlice";

const App: FC = () => {
  const [loading, setLoading] = useState(true); // Начальное состояние загрузки
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // const [isAuthOpen, setIsAuthOpen] = useState<boolean>(false);
  const { isAuth } = useSelector((state) => state.user);
  const { isModalOpen, isAuthOpen } = useSelector(
    (state) => state.navigationModal,
  );
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };
    fetchData();
  }, []);
  const dispatch = useDispatch();
  // Обрабатывает нажатия по оверлею
  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (event.currentTarget === event.target) {
      dispatch(setIsModalOpen(false));
      dispatch(setIsAuthOpen(false));
    }
  };

  // Функции открытия и закрытия модальных окон

  // const openAuth = () => {
  //   dispatch(setIsAuthOpen(true));
  // };

  // const closeAuth = () => {
  //   dispatch(setIsAuthOpen(false));
  // };
  useEffect(() => {
    if (location.pathname === "/login") {
      dispatch(setIsAuthOpen(true));
    }
  }, [dispatch, location.pathname]);

  if (loading) return <Loading />;
  return (
    <div onClick={handleOverlayClick} className={styles.App}>
      {location.pathname.includes("/confirmEmail") ? (
        ""
      ) : (
        <div className={styles.header}>
          <Header />
        </div>
      )}
      <AppRouter isAuthenticated={isAuth} />
      {/* {isModalOpen && (
        // <div className={styles.overlay} onClick={handleOverlayClick}>
        <div className={styles.burger}>
          <Burger
            onOpen={openAuth}
            isOpen={isModalOpen}
            onClose={closeModal}
          />
        </div>
        // </div>
      )} */}

      {isAuthOpen && (
        <div
          // onClick={handleOverlayClick}
          className={styles.overlay_auth}>
          <AuthForm />
        </div>
      )}
      <div
        className={styles.bottomNavigation}
        // onClick={handleOverlayClick}
      >
        <BottomBarPhone />
      </div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
  );
};
export default App;
