import React, { useState } from "react"
import s from "./homeNavigation.module.scss"
import myNews from "../../../../app/assets/home/navigation/myNews.svg"
import myNewsActive from "../../../../app/assets/home/navigation/myNewsActive.svg"
import manual from "../../../../app/assets/home/navigation/manual.svg"
import manualActive from "../../../../app/assets/home/navigation/manualActive.svg"
import support from "../../../../app/assets/home/navigation/support.svg"
import supportActive from "../../../../app/assets/home/navigation/supportActive.svg"
import faq from "../../../../app/assets/home/navigation/faq.svg"
import faqActive from "../../../../app/assets/home/navigation/faqActive.svg"
import arrow from "../../../../app/assets/home/navigation/arrow.svg"
import { useDispatch, useSelector } from "app/service/hooks/hooks"
import {
	setActiveTabHome,
	setOpenDoc,
	setOpenProtection,
} from "app/service/home/HomeSlice"
import { IRefProps } from "app/types/type"
import { SelectCountry } from "components/CreateLessonModal/ui/selectCountry/selectCountry"
import { CustomSelectCreateLessonModal } from "components/CreateLessonModal/ui/CustomSelect/customSelect"
import telegram from "../../../../app/assets/footer/Telegram.svg"
import inst from "../../../../app/assets/footer/instagram.svg"
export const HomeNavigation = ({
	scrollToSection,
	registrationRef,
	settingProfileRef,
	selectTutorialsRef,
	paymentRef,
	loadingTutorialsRef,
	socialRef,
	reviewsRef,
	supportsRef,
	drmRef,
	watermarkRef,
	accessControlRef,
}: IRefProps) => {
	const { activeTab, openDoc, openProtection } = useSelector(
		(state) => state.home,
	)

	const dispatch = useDispatch()
	const [view, setView] = useState<boolean>(true)
	const setCurrentTab = (
		tab: "news" | "manual" | "support" | "FAQ",
	) => {
		dispatch(setActiveTabHome(tab))
		// dispatch(setOpenDoc(!openDoc))
		// setView(!view)
	}

	const toggleOpenDoc = () => {
		dispatch(setOpenDoc(!openDoc))
		console.log(openDoc)
		// if (!openDoc && openProtection) {
		// 	// dispatch(setOpenProtection(false))
		// }
	}

	const toggleOpenProtection = () => {
		dispatch(setOpenProtection(true))
		// if (!openProtection && openDoc) {
		// 	dispatch(setOpenDoc(false))
		// }
	}

	const [activeManualItem, setActiveManualItem] = useState<
		string | null
	>(null)
	const handleManualItemClick = (
		itemName: string,
		ref: React.RefObject<HTMLDivElement>,
	) => {
		setActiveManualItem(itemName)
		if (scrollToSection) {
			scrollToSection(ref)
		}
	}
	return (
		<div className={s.homeNavigationContainer}>
			<ul className={s.navigation}>
				<li
					className={
						activeTab === "news"
							? s.navigationElementActive
							: s.navigationElement
					}
					onClick={() => setCurrentTab("news")}>
					<section className={s.title}>
						<img
							src={activeTab === "news" ? myNewsActive : myNews}
							alt="myNews"
						/>
						<h1>Мои новости</h1>
					</section>
					<p className={s.description}>
						Уроки и события людей, на
						<br />
						которых Вы подписаны
					</p>
				</li>
				<li
					className={
						activeTab === "manual"
							? s.navigationElementActive
							: s.navigationElement
					}
					onClick={() => setCurrentTab("manual")}>
					<section className={s.title}>
						<img
							src={activeTab === "manual" ? manualActive : manual}
							alt="myManual"
						/>
						<h1>Мои инструкции</h1>
					</section>
					<p className={s.description}>
						Информационная панель
						<br />
						возможностей и документации
					</p>
				</li>

				{activeTab === "manual" && view && (
					<ul className={s.manualList}>
						<div className={s.titleContainer} onClick={toggleOpenDoc}>
							<img
								src={arrow}
								alt="arrow"
								className={openDoc ? s.arrow : s.arrowClose}
							/>
							<h1 className={s.manualTitle}>
								Документация по
								<br />
								использованию платформы
							</h1>
						</div>
						{openDoc && (
							<>
								<li
									className={
										activeManualItem === "registration"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick(
											"registration",
											registrationRef,
										)
									}>
									Регистрация и вход
								</li>
								<li
									className={
										activeManualItem === "settingProfile"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick(
											"settingProfile",
											settingProfileRef,
										)
									}>
									Настройка профиля
								</li>
								<li
									className={
										activeManualItem === "selectTutorials"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick(
											"selectTutorials",
											selectTutorialsRef,
										)
									}>
									Выбор урока и обучение
								</li>
								<li
									className={
										activeManualItem === "payment"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick("payment", paymentRef)
									}>
									Покупка и оплата
								</li>
								<li
									className={
										activeManualItem === "loadingTutorials"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick(
											"loadingTutorials",
											loadingTutorialsRef,
										)
									}>
									Загрузка и размещение собственных уроков
								</li>
								<li
									className={
										activeManualItem === "social"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick("social", socialRef)
									}>
									Социальное взаимодействие и сообщество
								</li>
								<li
									className={
										activeManualItem === "reviews"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick("reviews", reviewsRef)
									}>
									Оставление отзывов и оценок
								</li>
								<li
									className={
										activeManualItem === "supports"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick("supports", supportsRef)
									}>
									Поддержка и помощь
								</li>
							</>
						)}
						<div
							className={s.titleContainer}
							onClick={toggleOpenProtection}>
							<img
								src={arrow}
								alt="arrow"
								className={`${s.arrow} ${
									openProtection ? s.arrowOpen : s.arrowClose
								}`}
							/>
							<h1 className={s.manualTitle}>Защиты видеоконтента </h1>
						</div>
						{openProtection && (
							<>
								<li
									className={
										activeManualItem === "DRM"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick("DRM", drmRef!)
									}>
									DRM шифрование файлов
								</li>
								<li
									className={
										activeManualItem === "watermark"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick("watermark", watermarkRef!)
									}>
									Водяные знаки
								</li>
								<li
									className={
										activeManualItem === "accessControl"
											? s.manualElementActive
											: s.manualElement
									}
									onClick={() =>
										handleManualItemClick(
											"accessControl",
											accessControlRef!,
										)
									}>
									Контроль доступа и <br />
									индивидуальная авторизация
								</li>
							</>
						)}
					</ul>
				)}

				<li
					className={
						activeTab === "support"
							? s.navigationElementActive
							: s.navigationElement
					}
					onClick={() => setCurrentTab("support")}>
					<section className={s.title}>
						<img
							src={activeTab === "support" ? supportActive : support}
							alt="mySupport"
						/>
						<h1>Мои обращения</h1>
					</section>
					<p className={s.description}>
						Служба поддержки пользователей
					</p>
				</li>
				<li
					className={
						activeTab === "FAQ"
							? s.navigationElementActive
							: s.navigationElement
					}
					onClick={() => setCurrentTab("FAQ")}>
					<section className={s.title}>
						<img
							src={activeTab === "FAQ" ? faqActive : faq}
							alt="faq"
						/>
						<h1 className={s.desktopVersion}>
							Часто задаваемые вопросы
						</h1>
						<h1 className={s.mobileVersion}>FAQ</h1>
					</section>
					<p className={s.description}>
						Cобрание часто задаваемых вопросов по
						<br />
						какой-либо теме и ответов на них
					</p>
				</li>
				<li
					className={s.footer}
					onClick={() => setCurrentTab("support")}>
					<div className={s.container}>
						<div className={s.topContainer}>
							<section>
								<h1 className={s.footerTitle}>
									Peremena group Limited Liability Company
								</h1>
							</section>
							<span className={s.bin}>BIN 241040102391</span>
							<button className={s.offer}>Публичная оферта</button>
							<button className={s.confidentiality}>
								Политика конфиденциальности
							</button>
						</div>

						<div className={s.mainBlock}>
							<span className={s.email}>
								support@makeupdate.online
							</span>
							<span className={s.imageBlock}>
								<img src={telegram} alt="telegram" />
								<img src={inst} alt="inst" />
							</span>
							<span className={s.rights}>
								© Все права сохранены. «PEREMENA GROUP», 2024.
							</span>
						</div>
					</div>
				</li>
			</ul>
				{activeTab === "manual" && (
			<div className={s.select}>
					<>
						<div onClick={() => toggleOpenDoc()}>
							<CustomSelectCreateLessonModal
								state={1}
								registrationRef={registrationRef}
								settingProfileRef={settingProfileRef}
								selectTutorialsRef={selectTutorialsRef}
								paymentRef={paymentRef}
								loadingTutorialsRef={loadingTutorialsRef}
								socialRef={socialRef}
								reviewsRef={reviewsRef}
								supportsRef={supportsRef}
								drmRef={drmRef}
								watermarkRef={watermarkRef}
								accessControlRef={accessControlRef}
							/>
						</div>
						<div onClick={() => toggleOpenProtection()}>
							<CustomSelectCreateLessonModal
								state={2}
								registrationRef={registrationRef}
								settingProfileRef={settingProfileRef}
								selectTutorialsRef={selectTutorialsRef}
								paymentRef={paymentRef}
								loadingTutorialsRef={loadingTutorialsRef}
								socialRef={socialRef}
								reviewsRef={reviewsRef}
								supportsRef={supportsRef}
								drmRef={drmRef}
								watermarkRef={watermarkRef}
								accessControlRef={accessControlRef}
							/>
						</div>
					</>
			</div>
				)}
		</div>
	)
}
