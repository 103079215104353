import { FC, useEffect, useRef, useState } from "react";

import img from "../../app/assets/lessons/img.svg";
import search from "../../app/assets/lessons/search.svg";
import { Lesson } from "./lesson";
import styles from "./Lessons.module.scss";
import {
  addLessonInBlackList,
  getLessons,
  getLessonsWithOutToken,
  getUsersProfileList,
  getUsersProfileListWithOutToken,
  loadMoreLessons,
  XURL,
} from "app/api/apiLessons";
import { useDispatch, useSelector } from "app/service/hooks/hooks";

import { ILesson, IUsersProfiles } from "app/types/type";
import { toast } from "react-toastify";
import { CustomFilter } from "components/LessonsUi/CustomFilter/CustomFilter";
import { Loading } from "components/Loading/Loading";

export const Lessons: FC = () => {
  const dispatch = useDispatch();
  const { lessons, profiles, activeFilter, usersProfiles } =
    useSelector((store) => store.lessons);

  const [loading, setLoading] = useState(true);
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const { userData, isAuth } = useSelector((store) => store.user);
  const [offset, setOffset] = useState(0);
  const [blacklistedLessons, setBlacklistedLessons] = useState<
    number[]
  >([]);
  const handleAddToBlacklist = (lessonId: number) => {
    addLessonInBlackList(dispatch, lessonId);
    setBlacklistedLessons((prev) => [...prev, lessonId]);
    toast.success("Видео  убрано из рекомендаций");
  };
  const filteredLessons = (lessons?.results ?? [])
    .filter(
      (lesson: ILesson) => !blacklistedLessons.includes(lesson.id),
    ) // Exclude blacklisted lessons
    .sort((a: ILesson, b: ILesson) => {
      if (activeFilter === "popularity") {
        return b.views - a.views;
      } else if (activeFilter === "date") {
        return (
          new Date(b.published_date).getTime() -
          new Date(a.published_date).getTime()
        );
      }
      return 0;
    });
  useEffect(() => {
    const fetchLessons = async () => {
      setLoading(true);
      try {
        await getLessons(dispatch); // Запрос первого набора уроков
      } catch (error) {
        console.error("Ошибка при загрузке уроков:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLessons();
  }, [dispatch]);
  useEffect(() => {
    const fetchProfiles = async () => {
      if (!loading && lessons?.results) {
        const uniqueUserIds = new Set<number>();
        lessons.results.forEach((lesson: ILesson) => {
          uniqueUserIds.add(lesson?.user_id!);
        });
        const userIdArray = Array.from(uniqueUserIds);

        if (userIdArray.length === 0) return;
        const idsString = userIdArray.join(",");
        if (isAuth) {
          getUsersProfileList(dispatch, idsString);
        } else {
          getUsersProfileListWithOutToken(dispatch, idsString);
        }
      }
    };
    fetchProfiles();
  }, [lessons, loading, dispatch]); // Запускаем только при изменении уроков

  useEffect(() => {
    const loadMore = async () => {
      if (lessons?.next && !loading) {
        console.log("Подгружаем дополнительные уроки");
        setLoading(true);
        await loadMoreLessons(
          dispatch,
          `${XURL}/api/v1/lessons/homepage/?limit=20&offset=${
            offset + 20
          }`,
        );
        setOffset((prev) => prev + 20); // Увеличиваем смещение для следующей загрузки
        setLoading(false);
        // await fetchProfiles(); // Запрос профилей для вновь загруженных уроков
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && lessons?.next && !loading) {
          loadMore();
        }
      },
      { threshold: 1.0 },
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [lessons?.next, loading, dispatch, offset]);

  const [currentId, setCurrentId] = useState<number | null>(null);

  useEffect(() => {
    // Проверяем, если profileData существует, обновляем currentId
    if (userData?.user_id) {
      setCurrentId(userData.user_id);
    }
  }, [userData?.user_id]);
  const profilesMap = Array.isArray(usersProfiles)
    ? usersProfiles.reduce(
        (
          acc: Record<number, IUsersProfiles>,
          profile: IUsersProfiles,
        ) => {
          acc[profile.user_id] = profile;
          return acc;
        },
        {},
      )
    : {};

  return (
    <div className={styles.lessons_container}>
      {!lessons ? (
        <Loading />
      ) : (
        <>
          <div className={styles.filter_block}>
            <div className={styles.search_filter}>
              <div className={styles.filter}>
                <CustomFilter threeTab={false} />
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.search}>
                  <img className={styles.vol} src={img} alt='' />
                  <input
                    className={styles.input}
                    placeholder='Поиск'
                    type='text'
                  />
                  <img
                    className={styles.search_img}
                    src={search}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.all_lessons_box}>
            {filteredLessons?.map((lesson: ILesson, index) => (
              <Lesson
                key={index}
                currentId={currentId!}
                lessonData={lesson}
                profileData={
                  profilesMap[lesson?.user_id!] ||
                  profilesMap[userData?.user_id!] ||
                  null
                }
                onBlacklist={() => handleAddToBlacklist(lesson.id)}
              />
            ))}
          </div>
        </>
      )}
      <div ref={loaderRef}></div>
    </div>
  );
};
