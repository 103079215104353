// burgerProfilesSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IBurgerState,
  ICreateLessonState,
  IGetUserData,
} from "app/types/type";

const initialState: ICreateLessonState = {
  nameLesson: null,
  descriptionLesson: null,
  priceLesson: null,
  videoDuration: null,
  imageSrc: null,
};

export const createLessonSlice = createSlice({
  name: "createLesson",
  initialState,
  reducers: {
    setNameLesson: (state, action: PayloadAction<string>) => {
      state.nameLesson = action.payload;
    },
    setDescriptionLesson: (state, action: PayloadAction<string>) => {
      state.descriptionLesson = action.payload;
    },
    setPriceLesson: (state, action: PayloadAction<number>) => {
      state.priceLesson = action.payload;
    },
    setVideoDuration: (state, action: PayloadAction<number>) => {
      state.videoDuration = action.payload;
    },
    setImageSrc: (state, action: PayloadAction<string>) => {
      state.imageSrc = action.payload;
    },
  },
});

export const {
  setNameLesson,
  setDescriptionLesson,
  setImageSrc,
  setPriceLesson,
  setVideoDuration,
} = createLessonSlice.actions;

export default createLessonSlice.reducer;
