import React from "react"
import s from "./mainBlock.module.scss"
import { MainCardElement } from "./MainCardElement/MainCardElement"
import { MainEventElement } from "./MainEventElement/MainEventElement"
import { useSelector } from "app/service/hooks/hooks"
import { Manual } from "../Navigation/Manual/Manual"
import { Support } from "../Navigation/Support/Support"
import { FAQ } from "../Navigation/FAQ/FAQ"
import { ILesson, IRefProps } from "app/types/type"
import { RightSideVideo } from "components/PlayerUi/RightSideVideo/RightSideVideo"
import { LessonCardElement } from "./LessonCardElement/LessonCardElement"
import { Lesson } from "pages/Lessons/lesson"
import { SmallLessonCardElement } from "./SmallLessonCardElement/smallLessonCardElement"
import { AdBlock } from "../Ad/AdBlock"

export const MainBlock = ({
	scrollToSection,
	registrationRef,
	settingProfileRef,
	selectTutorialsRef,
	paymentRef,
	loadingTutorialsRef,
	socialRef,
	reviewsRef,
	supportsRef,
	drmRef,
	watermarkRef,
	accessControlRef,
}: IRefProps) => {
	const { activeTab } = useSelector((state) => state.home)
	const { lessons } = useSelector((state) => state.lessons)
	return (
		<div className={s.wrapper}>
			{activeTab === "news" ? (
				<>
					<div className={s.adBlock}>
						<AdBlock text="реклама" />
					</div>
					<div className={s.cardElement}>
						{/* <LessonCardElement /> */}
						<SmallLessonCardElement />
						<SmallLessonCardElement />
						<SmallLessonCardElement />
					</div>
					<div className={s.cardElement}>
						{/* <LessonCardElement /> */}
						<SmallLessonCardElement />
						<SmallLessonCardElement />
						<SmallLessonCardElement />
					</div>
					<div className={s.adBlock}>
						<AdBlock text="реклама" />
					</div>
					<div className={s.cardElement}>
						{/* <LessonCardElement /> */}
						<SmallLessonCardElement />
						<SmallLessonCardElement />
						<SmallLessonCardElement />
					</div>
					<div className={s.cardElement}>
						{/* <LessonCardElement /> */}
						<SmallLessonCardElement />
						<SmallLessonCardElement />
						<SmallLessonCardElement />
					</div>
					<div className={s.adBlock}>
						<AdBlock text="реклама" />
					</div>
					<div className={s.cardElement}>
						{/* <LessonCardElement /> */}
						<SmallLessonCardElement />
						<SmallLessonCardElement />
						<SmallLessonCardElement />
					</div>
					<div className={s.cardElement}>
						{/* <LessonCardElement /> */}
						<SmallLessonCardElement />
						<SmallLessonCardElement />
						<SmallLessonCardElement />
					</div>
				</>
			) : activeTab === "manual" ? (
				<Manual
					registrationRef={registrationRef}
					settingProfileRef={settingProfileRef}
					selectTutorialsRef={selectTutorialsRef}
					paymentRef={paymentRef}
					loadingTutorialsRef={loadingTutorialsRef}
					socialRef={socialRef}
					reviewsRef={reviewsRef}
					supportsRef={supportsRef}
					drmRef={drmRef}
					watermarkRef={watermarkRef}
					accessControlRef={accessControlRef}
				/>
			) : activeTab === "support" ? (
				<Support />
			) : activeTab === "FAQ" ? (
				<FAQ />
			) : null}
		</div>
	)
}
