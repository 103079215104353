import { IFollower, IPropsUsers } from "app/types/type"
import React from "react"
import s from "./userCardSmall.module.scss"
import video from "../../../app/assets/users/video.svg"
import view from "../../../app/assets/users/view.svg"
export const UserCardSmall = ({
	userData,
	totalViews,
	countLessons,
}: IPropsUsers) => {
	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				<div className={s.leftSide}>
					<img
						src={`https://api.lr45981.tw1.ru${userData.photo}`}
						alt="profilePhoto"
						className={s.profilePhoto}
					/>
					<div className={s.profileInfo}>
						<section>
							<h1 className={s.name}>
								{userData.first_name && userData.last_name
									? `${userData.first_name} ${userData.last_name}`
									: "Имя Фамилия"}
							</h1>
						</section>
						<span className={s.location}>
							{userData.country && userData.city
								? `${userData.country} ${userData.city}`
								: "Страна, Город"}
						</span>
					</div>
				</div>
				<div className={s.rightSide}>
					<div className={s.video}>
						<img src={video} alt="video_img" />
						<span className={s.text}>12</span>
					</div>
					<div className={s.view}>
						<img src={view} alt="view_img" />
						<span className={s.text}>12333</span>
					</div>
				</div>
			</div>
		</div>
	)
}
