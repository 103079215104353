import React from "react";
import s from "./stepFour.module.scss";
import video from "../../../../app/assets/createLessonModal/video.png";
import { CustomSelectCreateLessonModal } from "components/CreateLessonModal/ui/CustomSelect/customSelect";
import { SelectCountry } from "components/CreateLessonModal/ui/selectCountry/selectCountry";
import { useSelector } from "app/service/hooks/hooks";
export const StepFour = () => {
  const { nameLesson, descriptionLesson, priceLesson, imageSrc } =
    useSelector((state) => state.createLesson);
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.videoContainer}>
          <img src={imageSrc ? imageSrc : video} alt='' />
        </div>
        <div className={s.nameVideo}>
          <span className={s.label}>Название урока</span>
          <span className={s.text}>{nameLesson}</span>
        </div>
        <div className={s.descriptionVideo}>
          <span className={s.label}>Описание урока</span>
          <span className={s.text}>{descriptionLesson}</span>
        </div>
        <div className={s.country}>
          <section>
            <h1 className={s.countryTitle}>
              Урок будет доступен на следующих языках
            </h1>
          </section>
          <SelectCountry />
        </div>
        <div className={s.setPrice}>
          {priceLesson && (
            <input
              disabled
              type='number'
              className={s.setPriceInput}
              value={priceLesson}
            />
          )}
          <span className={s.line}></span>
          <span className={s.value}>Руб.</span>
        </div>
      </div>
    </div>
  );
};
