import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import authSlice from "./auth/authSlice";
import profileCardSlice from "./profileCard/profileCardSlice";
import headerSlice from "./header/headerSlice";
import lessonsSlice from "./lessons/lessonsSlice";
import usersSlice from "./users/UsersSlice";
import playerSlice from "./player/playerSlice";
import HomeSlice from "./home/HomeSlice";
import burgerProfilesSlice from "./burgerProfiles/burgerSlice";
import createLessonSlice from "./createLesson/CreateLessonSlice";
import navigationModalSlice from "./navigationModals/NavigationModalsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authSlice,
    profileCard: profileCardSlice,
    header: headerSlice,
    lessons: lessonsSlice,
    users: usersSlice,
    player: playerSlice,
    home: HomeSlice,
    burgerProfiles: burgerProfilesSlice,
    createLesson: createLessonSlice,
    navigationModal: navigationModalSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
