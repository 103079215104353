import { FC, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
// Styles
import styles from "./BottomBarPhone.module.scss"
// Image import
import events from "./img/events.svg"
import home from "./img/home.svg"
import lessons from "../../app/assets/profileCard/videosWhiteIcon.svg"
import top_arrow from "./img/top_arrow.svg"
import usersPhoto from "../Burger/img/motinova.svg"
import users from "./img/users.svg"
import { useDispatch, useSelector } from "app/service/hooks/hooks"
import icon_profile from "../../app/assets/other/profile_icon.svg"
import plus from "../../app/assets/other/plusMobile.svg"
import { Burger } from "components/Burger/Burger"
import { AuthForm } from "components/AuthForm/AuthForm"
import {
	setIsAuthOpen,
	setIsModalOpen,
	toggleIsModalOpen,
} from "app/service/navigationModals/NavigationModalsSlice"

export const BottomBarPhone: FC = () => {
	const { userData } = useSelector((state) => state.user)
	const photoLink = "https://api.lr45981.tw1.ru" + userData?.photo
	const dispatch = useDispatch()
	const location = useLocation()
	// const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	// const [isAuthOpen, setIsAuthOpen] = useState<boolean>(false);
	const { isAuth } = useSelector((state) => state.user)
	const { isModalOpen, isAuthOpen } = useSelector(
		(store) => store.navigationModal,
	)
	// const handleOverlayClick = (
	//   event: React.MouseEvent<HTMLDivElement>,
	// ) => {
	//   if (event.currentTarget === event.target) {
	//     setIsModalOpen(false);
	//     setIsAuthOpen(false);
	//   }
	// };

	// const openModal = () => {
	//   setIsModalOpen(true);
	// };
	// console.log(isModalOpen);

	// const closeModal = () => {
	//   setIsModalOpen(false);
	// };

	// const openAuth = () => {
	//   setIsAuthOpen(true);
	// };
	// console.log(isAuthOpen);

	// const closeAuth = () => {
	//   setIsAuthOpen(false);
	// };
	// useEffect(() => {
	//   if (location.pathname === "/login") {
	//     openAuth();
	//   }
	// }, [location.pathname]);
	return (
		<div className={styles.mobileNavigationContainer}>
			<nav className={styles.navigate}>
				<ul className={styles.navigateList}>
					<Link to="/" className={styles.link}>
						<li className={styles.listElement}>
							<img
								className={styles.img_home}
								src={home}
								alt="home"
							/>
						</li>
						<span>Главная</span>
					</Link>

					<Link to="/lessons" className={styles.link}>
						<li className={styles.listElement}>
							<img
								className={styles.img_lesson}
								src={lessons}
								alt="lessons"
							/>
						</li>
						<span>Уроки</span>
					</Link>
					<li className={styles.listElement}>
						<img
							className={styles.img_plus}
							src={plus}
							alt="lessons"
						/>
					</li>
					<Link to="/users" className={styles.link}>
						<li className={styles.listElement}>
							<img
								className={styles.img_users}
								src={users}
								alt="users"
							/>
						</li>
						<span>Пользователи</span>
					</Link>

					<Link to="/events" className={styles.link}>
						<li className={styles.listElement}>
							<img
								className={styles.img_event}
								src={events}
								alt="events"
							/>
						</li>
						<span>События</span>
					</Link>
				</ul>
				{/* <Link to='/profile' className={styles.link}> */}
				<div
					className={styles.profileContainer}
					onClick={() => {
						dispatch(toggleIsModalOpen())
					}}>
					<div
						className={
							isModalOpen ? styles.open : styles.profileLink
						}>
						{userData?.photo ? (
							<img
								className={styles.img_icon}
								src={photoLink}
								alt="icon_profile"
							/>
						) : (
							<img
								className={styles.img_icon}
								src={icon_profile}
								alt="icon_profile"
							/>
						)}
						<img
							className={styles.img_link}
							src={top_arrow}
							alt="top_arrow"
						/>
					</div>
				</div>

				{/* </Link> */}
				{isModalOpen && (
					// <div className={styles.burger}>
					<Burger
					// onOpen={openAuth}
					// isOpen={isModalOpen}
					// onClose={closeModal}
					/>
					// </div>
				)}
				{isAuthOpen && (
					// <div className={styles.overlay_auth}>
					<AuthForm />
					// </div>
				)}
			</nav>
		</div>
	)
}
