import React, { useEffect } from "react"
import s from "./manual.module.scss"
import { IRefProps } from "app/types/type"
import { useSelector } from "app/service/hooks/hooks"
export const Manual = ({
	scrollToSection,
	registrationRef,
	settingProfileRef,
	selectTutorialsRef,
	paymentRef,
	loadingTutorialsRef,
	socialRef,
	reviewsRef,
	supportsRef,
	drmRef,
	watermarkRef,
	accessControlRef,
}: IRefProps) => {
	const { openDoc, openProtection } = useSelector(
		(store) => store.home,
	)
	useEffect(() => {
		console.log("openDoc has changed:", openDoc)
		console.log("openProtec has changed:", openProtection)
	}, [openDoc, openProtection])
	return (
		<div
			className={
				openDoc ? s.open : openProtection ? s.protection : s.wrapper
			}>
			<div className={s.container}>
				{!openProtection ? (
					<>
						<p className={s.about}>
							Платформа MAKEUPDATE создана для того, чтобы сделать
							процесс обучения в индустрии красоты доступным,
							качественным и удобным, а самое главное – глобальным. С
							помощью MAKEUPDATE вы сможете не только учиться, но и
							делиться своими знаниями с другими, развиваться
							профессионально и создавать новые возможности для роста.
							Мы стремимся сделать ваше обучение продуктивным и
							интересным, предоставляя все необходимые инструменты и
							поддержку на каждом шагу.
						</p>
						<section id="registration" ref={registrationRef}>
							<h1 className={s.title}>Регистрация и вход</h1>
							<span className={s.stepsTitle}>
								Чтобы начать использовать платформу MAKEUPDATE,
								выполните следующие шаги:
							</span>
							<ul className={s.stepsList}>
								<li className={s.stepsElement}>
									Перейдите на сайт: Зайдите на официальный сайт
									MAKEUPDATE и нажмите на кнопку{" "}
									<a href="/login">Стать пользователем </a> в верхнем
									правом углу страницы.
								</li>
								<li className={s.stepsElement}>
									Заполните форму регистрации: Введите свою
									действующую электронную почту и придумайте надежный
									пароль. Рекомендуется использовать сложный пароль,
									чтобы обеспечить безопасность вашего аккаунта.
								</li>
								<li className={s.stepsElement}>
									Подтвердите регистрацию: После заполнения формы
									регистрации вы получите письмо на указанную
									электронную почту. Перейдите по ссылке из письма,
									чтобы подтвердить учетную запись и активировать
									аккаунт.
								</li>
								<li className={s.stepsElement}>
									Авторизация: Используйте ваши учетные данные
									(электронную почту и пароль), чтобы войти на
									платформу. Вход также возможен через интеграцию с
									социальными сетями.
								</li>
							</ul>
						</section>
						<section id="settingProfile" ref={settingProfileRef}>
							<h1 className={s.title}>Настройка профиля</h1>
							<span className={s.stepsTitle}>
								Настройте свой профиль, чтобы сделать его более
								информативным и привлекательным для других
								пользователей и потенциальных клиентов:
							</span>
							<ul className={s.stepsList}>
								<li className={s.stepsElement}>
									Заполните информацию о себе: Перейдите в раздел
									<a href="/profile"> "Профиль" </a>. Здесь вы сможете
									указать свое имя и фамилию, загрузить фотографию
									профиля, а также заполнить информацию о своем
									местоположении — страну и город.
								</li>
								<li className={s.stepsElement}>
									Добавьте контактные данные: Укажите номера
									телефонов, ссылки на мессенджеры (WhatsApp,
									Telegram). Эти данные помогут потенциальным клиентам
									связаться с вами для бронирования или консультации.
									Обратите внимание, что указание контактных данных
									повысит доверие и улучшит видимость вашего профиля в
									системе поиска.
								</li>
							</ul>
						</section>
						<section id="selectTutorials" ref={selectTutorialsRef}>
							<h1 className={s.title}>Выбор урока и обучение</h1>
							<span className={s.stepsTitle}>
								MAKEUPDATE предлагает огромное количество обучающих
								материалов для всех уровней подготовки:
							</span>
							<ul className={s.stepsList}>
								<li className={s.stepsElement}>
									Поиск нужного урока: Перейдите в раздел
									<a href="/lessons"> "Уроки" </a> и используйте
									строку поиска для нахождения интересующего вас
									материала. Введите ключевые слова, чтобы упростить
									поиск.
								</li>
								<li className={s.stepsElement}>
									Использование фильтров: Вы можете применять фильтры
									по дате публикации и популярности, чтобы
									отсортировать уроки и найти самый актуальный или
									популярный материал.
								</li>
								<li className={s.stepsElement}>
									Ознакомление с уроком: Нажмите на выбранный урок,
									чтобы ознакомиться с его описанием. Это поможет вам
									сделать правильный выбор.
								</li>
								<li className={s.stepsElement}>
									Покупка и доступ: После выбора урока необходимо
									оплатить его. Урок станет доступным в вашем личном
									кабинете в разделе{" "}
									<a href="/profile">" Доступ к урокам "</a> на срок
									90 дней.
								</li>
							</ul>
						</section>
						<section id="payment" ref={paymentRef}>
							<h1 className={s.title}>Покупка и оплата</h1>
							<span className={s.stepsTitle}>
								Процесс покупки уроков на MAKEUPDATE интуитивно
								понятен:
							</span>
							<ul className={s.stepsList}>
								<li className={s.stepsElement}>
									Оплата: Оплатить урок можно с помощью банковской
									карты, в том числе международных карт. Все
									транзакции проводятся с использованием защищенных
									платежных шлюзов для обеспечения безопасности.
								</li>
								<li className={s.stepsElement}>
									Доступ к материалам: После успешной оплаты вы
									получите доступ к уроку на 90 дней. Весь оплаченный
									контент будет отображен в вашем профиле на вкладке{" "}
									<a href="/profile">" Доступ к урокам "</a>, что
									позволит легко находить приобретенные уроки и
									следить за своим обучением.
								</li>
							</ul>
						</section>
						<section id="loadingTutorials" ref={loadingTutorialsRef}>
							<h1 className={s.title}>
								Загрузка и размещение собственных уроков
							</h1>
							<span className={s.stepsTitle}>
								Если вы профессиональный визажист и хотите делиться
								своими знаниями, MAKEUPDATE предоставляет вам
								возможность стать преподавателем:
							</span>
							<ul className={s.stepsList}>
								<li className={s.stepsElement}>
									Стать продавцом: Зайдите в раздел
									<a href="/#">" Cтать продавцом "</a> и заполните
									заявку, указав свои данные.
								</li>
								<li className={s.stepsElement}>
									Создание уроков: После одобрения заявки, переходите
									к загрузке материалов. Загрузите видеозаписи,
									добавьте описание, укажите стоимость
								</li>
								<li className={s.stepsElement}>
									Управление курсами: В разделе{" "}
									<a href="/profile">" Мои уроки "</a> вы можете
									управлять своими уроками, редактировать описание,
									следить за продажами и просматривать статистику
									доходов.
								</li>
								<li className={s.stepsElement}>
									Удаление урока: Если продавец решит удалить свой
									урок, это можно сделать только через 90 дней после
									последней покупки, чтобы соблюсти обязательства
									перед покупателями.
								</li>
							</ul>
						</section>
						<section id="social" ref={socialRef}>
							<h1 className={s.title}>
								Социальное взаимодействие и общество
							</h1>
							<span className={s.stepsTitle}>
								MAKEUPDATE — это не только платформа для обучения, но
								и сообщество единомышленников:
							</span>
							<ul className={s.stepsList}>
								<li className={s.stepsElement}>
									Сообщество: В разделе{" "}
									<a href="/users">" Пользователи "</a> вы можете
									найти коллег и начать следить за их новостями.
								</li>
								<li className={s.stepsElement}>
									Подписка на участников: Подписывайтесь на других
									преподавателей и студентов, чтобы следить за их
									обновлениями и быть в курсе новостей индустрии.
								</li>
								<li className={s.stepsElement}>
									Личные сообщения: Отправляйте и получайте личные
									сообщения, общайтесь напрямую и развивайте
									профессиональные связи.
								</li>
							</ul>
						</section>
						<section id="reviews" ref={reviewsRef}>
							<h1 className={s.title}>Оставление отзывов и оценок</h1>
							<span className={s.stepsTitle}>
								Отзывы играют ключевую роль в улучшении качества
								контента и помогают пользователям выбирать лучший
								материал:
							</span>
							<ul className={s.stepsList}>
								<li className={s.stepsElement}>
									Оставляйте отзывы: После завершения урока, оставьте
									отзыв преподавателю. Это поможет другим
									пользователям найти качественные материалы и поможет
									преподавателю улучшить свои курсы.
								</li>
								<li className={s.stepsElement}>
									Чтение отзывов: Прежде чем купить курс, ознакомьтесь
									с отзывами других пользователей, чтобы убедиться в
									его качестве.
								</li>
							</ul>
						</section>
						<section id="supports" ref={supportsRef}>
							<h1 className={s.title}>Поддержка и помощь</h1>
							<span className={s.stepsTitle}>
								MAKEUPDATE стремится обеспечить вам максимальный
								комфорт и поддержку на всех этапах работы с
								платформой:
							</span>
							<ul className={s.stepsList}>
								<li className={s.stepsElement}>
									Связь со службой поддержки: Если у вас возникнут
									вопросы, используйте форму обратной связи на сайте
									или отправьте письмо на нашу электронную почту:
									support@makeupdate.online. Также можно
									воспользоваться разделом{" "}
									<a href="/#">" Обращения "</a> на главной странице.
								</li>
								<li className={s.stepsElement}>
									Быстрое решение проблем: Наша служба поддержки
									всегда готова помочь вам решить любые технические
									проблемы или ответить на ваши вопросы, чтобы сделать
									ваше пребывание на платформе комфортным и
									продуктивным.
								</li>
							</ul>
						</section>
					</>
				) : (
					openProtection && (
						<>
							<p className={s.about}>
								Платформа MAKEUPDATE делает защиту видеоконтента
								приоритетной задачей, гарантируя безопасность
								интеллектуальной собственности преподавателей и
								ограничивая несанкционированный доступ. Для этого
								используется ряд современных технологий и методов
								защиты, включая DRM шифрование, которые обеспечивают
								максимальную защиту материалов на платформе.
							</p>
							<section id="DRM" ref={drmRef}>
								<h1 className={s.title}> DRM шифрование файлов</h1>
								<span className={s.stepsTitle}>
									Все видеоуроки на платформе MAKEUPDATE защищены с
									использованием технологии DRM (Digital Rights
									Management) шифрования. Это один из самых надежных
									методов защиты мультимедийного контента, который
									позволяет предотвратить несанкционированное
									скачивание, копирование и распространение видео
								</span>
								<ul className={s.stepsList}>
									<li className={s.stepsElement}>
										Невозможность скачивания файлов из браузера: DRM
										шифрование защищает видео таким образом, что его
										невозможно скачать с использованием стандартных
										методов загрузки из браузера или при помощи
										сторонних плагинов. Даже если злоумышленник
										каким-то образом сможет загрузить зашифрованный
										файл, его невозможно будет воспроизвести из-за
										отсутствия ключа дешифровки
									</li>
									<li className={s.stepsElement}>
										Защита от скриншотов и записи экрана: На
										устройствах с операционной системой iOS и в
										браузере Safari на OS X невозможно сделать
										скриншот или записать экран во время проигрывания
										видео. Эта функция предотвращает создание копий
										контента путем записи экрана, что делает защиту
										еще более комплексной.
									</li>
									<li className={s.stepsElement}>
										Работа защиты против плагинов и программ:
										Технология DRM обеспечивает защиту контента как от
										плагинов браузера (например, SaveFrom и подобных),
										так и от отдельных программ, предназначенных для
										захвата видео, таких как VLC или ffmpeg. Даже с
										использованием продвинутых инструментов для записи
										и скачивания, пользователь не сможет воспроизвести
										защищенный контент.
									</li>
									<li className={s.stepsElement}>
										DRM-шифрование и водяные знаки помогают защитить
										видео от несанкционированного скачивания.
									</li>
								</ul>
							</section>
							<section id="watermark" ref={watermarkRef}>
								<h1 className={s.title}>Водяные знаки</h1>
								<span className={s.stepsTitle}>
									Водяные знаки используются для дополнительной защиты
									видеоконтента и предупреждения записи экрана
									рядовыми пользователями:
								</span>
								<ul className={s.stepsList}>
									<li className={s.stepsElement}>
										Индивидуальные водяные знаки: В плеер встраивается
										водяной знак, который является уникальным для
										каждого зрителя. Этот знак появляется в плеере при
										просмотре видео, что позволяет идентифицировать
										источник записи в случае несанкционированного
										распространения.
									</li>
									<li className={s.stepsElement}>
										Данные пользователя: Водяным знаком будут являться
										данные, которые пользователь указывал при покупке
										курса,колнкретно, адрес электронной почты. Это
										добавляет дополнительный уровень защиты, так как
										водяной знак связан с конкретным пользователем.
									</li>
									<li className={s.stepsElement}>
										Покрытие большого участка экрана: Водяные знаки на
										платформе MAKEUPDATE появляются в разных частях
										картинки, каждые 10 секунд, что делает невозможным
										их обрезку в видеоредакторе, как это часто делают
										со знаками, которые ставятся только в углу экрана.
										Такая защита значительно усложняет создание
										нелегальных копий контента.
									</li>
								</ul>
							</section>
							<section id="accessControl" ref={accessControlRef}>
								<h1 className={s.title}>
									Контроль доступа и индивидуальная авторизация
								</h1>
								<span className={s.stepsTitle}>
									Каждому пользователю предоставляется уникальный
									доступ к контенту на платформе. Это достигается с
									помощью следующих механизмов:
								</span>
								<ul className={s.stepsList}>
									<li className={s.stepsElement}>
										Индивидуальные ключи доступа: Каждому
										зарегистрированному пользователю присваивается
										уникальный ключ доступа к видеоурокам. Эти ключи
										необходимы для расшифровки видео и их просмотра,
										что делает каждое подключение уникальным и
										отслеживаемым
									</li>
									<li className={s.stepsElement}>
										Отслеживание действий пользователя: Все действия
										пользователей, включая попытки доступа к контенту,
										фиксируются в системе. Это позволяет отслеживать
										несанкционированные попытки использования контента
										и принимать меры, направленные на защиту
										материалов.
									</li>
									<li className={s.stepsElement}>
										Авторизация через профиль: Доступ к видеоурокам
										возможен только через авторизованный профиль на
										платформе MAKEUPDATE. Это исключает возможность
										распространения ссылки на видео среди
										неавторизованных пользователей, так как каждый
										пользователь должен войти в свою учетную запись,
										чтобы получить доступ к контенту.
									</li>
								</ul>
							</section>
						</>
					)
				)}
			</div>
		</div>
	)
}
