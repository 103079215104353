import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IHomeState } from "app/types/type"

const initialState: IHomeState = {
	activeTab: "news",
	openDoc: false,
	openProtection: false,
	isOpenList: false,
}

export const homeSlice = createSlice({
	name: "home",
	initialState: initialState,
	reducers: {
		setActiveTabHome: (
			state,
			action: PayloadAction<"news" | "manual" | "support" | "FAQ">,
		) => {
			state.activeTab = action.payload
		},
		setOpenDoc: (state, action: PayloadAction<boolean>) => {
			state.openDoc = action.payload
		},
		setOpenProtection: (state, action: PayloadAction<boolean>) => {
			state.openProtection = action.payload
		},
		setIsOpenList: (state, action: PayloadAction<boolean>) => {
			state.isOpenList = action.payload
		},
	},
})
export const {
	setActiveTabHome,
	setOpenDoc,
	setOpenProtection,
	setIsOpenList,
} = homeSlice.actions

export default homeSlice.reducer
