import { FC, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import styles from "./Burger.module.scss"
import noUser from "./img/photo_undefined.svg"
import icon_profile from "../../app/assets/other/profile_icon.svg"
import { BurgerAuth, ModalWindow } from "../../app/types/modal"
import { toast } from "react-toastify"
import {
	logoutProfile,
	refreshTokenForBurger,
} from "helpers/localStorage.helper"
import { useDispatch, useSelector } from "app/service/hooks/hooks"
import {
	logout,
	setIsAuth,
	setUserData,
} from "app/service/user/userSlice"
import { AuthService } from "app/service/servise"
import { getDataUser } from "app/api/api"
import {
	addProfile,
	removeProfile,
	setActiveProfile,
} from "app/service/burgerProfiles/burgerSlice"
import { IGetUserData } from "app/types/type"
import {
	setIsAuthOpen,
	setIsModalOpen,
} from "app/service/navigationModals/NavigationModalsSlice"

export const Burger = () => {
	const dispatch = useDispatch()

	const { isModalOpen } = useSelector(
		(store) => store.navigationModal,
	)
	const { profiles, activeProfile } = useSelector(
		(state) => state.burgerProfiles,
	)

	const { isAuth, userData } = useSelector((state) => state.user)

	const logOut = () => {
		if (!refresh && !access) {
			toast.error("Вы уже вышли с аккаунта")
			console.log(1)
		} else {
			// logoutProfile()
			dispatch(setIsModalOpen(false))
			dispatch(logout())
			toast.success("Успешно!")
			if (profiles.length > 1) {
				// Если больше одного профиля, удаляем токены только для активного профиля

				// const activeAccount = profiles.find(
				// 	(el) => el?.user_id === activeProfile,
				// )
				console.log("activeAcc", activeProfile)

				if (activeProfile) {
					localStorage.removeItem(`refreshToken_${activeProfile}`)
					localStorage.removeItem(`accessToken_${activeProfile}`)
					dispatch(removeProfile(+activeProfile))
					const updatedProfiles = profiles.filter(
						(profile) => profile.user_id !== +activeProfile,
					)
					if (updatedProfiles.length > 0) {
						// Получаем ID следующего активного профиля
						const nextActiveProfile = updatedProfiles[0]
						handleProfileClick(nextActiveProfile)
					}
					// if (updatedProfiles.length > 0) {
					// 	const nextActiveProfile = updatedProfiles[0].user_id
					// 	localStorage.setItem(
					// 		"activeAcc",
					// 		nextActiveProfile.toString(),
					// 	)
					// Установите новый активный профиль в Redux (если необходимо)
					// dispatch(setActiveProfile(nextActiveProfile))
					// console.log("nextActive", nextActiveProfile)
					// const refreshTokenNextAcc = localStorage.getItem(
					// 	`refreshToken_${nextActiveProfile}`,
					// )
					// if (refreshTokenNextAcc) {
					// 	refreshTokenForBurger(refreshTokenNextAcc)
					// 	getDataUser(dispatch)
					// }
					// }
				}
			} else if (profiles.length === 1) {
				// Если один профиль, удаляем все токены, включая токены активного аккаунта
				console.log("tyt")
				profiles.forEach((el) => {
					localStorage.removeItem(`refreshToken_${el?.user_id}`)
					localStorage.removeItem(`accessToken_${el?.user_id}`)
				})
				localStorage.removeItem(`refreshToken`)
				localStorage.removeItem(`accessToken`)
				localStorage.removeItem("profiles")
				dispatch(setUserData({} as IGetUserData))
				localStorage.setItem("isAuth", "false")
				dispatch(setIsAuth(false))
				localStorage.removeItem("activeAcc")
			}
		}
	}
	useEffect(() => {
		localStorage.setItem("profiles", JSON.stringify(profiles))
	}, [profiles])

	if (!isModalOpen) return null

	const access = localStorage.getItem("accessToken")
	const refresh = localStorage.getItem("refreshToken")

	const photoLink = "https://api.lr45981.tw1.ru" + userData?.photo
	console.log("active", activeProfile)
	console.log("profiles", profiles)

	// const handleAddProfile = async () => {
	//   if (profiles.length !== 0) {
	//     if (onOpen) {
	//       onOpen();
	//     }
	//     //   try {
	//     //     const data = await AuthService.login({
	//     //       email,
	//     //       password,
	//     //     });
	//     //     dispatch(addProfile(data.userProfile));
	//     //   } catch (error) {
	//     //     console.error("Ошибка входа:", error);
	//     //   }
	//     // } else {
	//     //   // Если профили уже есть, выполняем refreshToken() и добавляем новый профиль
	//     try {
	//       const refresh = localStorage.getItem("refreshToken");
	//       console.log(refresh);
	//       // await refreshTokenForBurger(refresh && refresh);
	//       await getDataUser(dispatch); // Функция получения данных профиля
	//       // dispatch(addProfile(newProfileData));
	//     } catch (error) {
	//       console.error("Ошибка обновления токена:", error);
	//     }
	//   } else {
	//     if (onOpen) {
	//       onOpen();
	//     }
	//   }
	// };
	const handleProfileClick = async (profile: IGetUserData) => {
		// Обновляем активный профиль
		dispatch(setActiveProfile(profile.user_id))
		localStorage.setItem("activeAcc", profile.user_id.toString())
		try {
			const refresh = localStorage.getItem(
				`refreshToken_${profile.user_id}`,
			)
			if (refresh) {
				await refreshTokenForBurger(refresh)
			}

			// Получаем данные профиля
			await getDataUser(dispatch) // или вызываете свою функцию для получения данных
		} catch (error) {
			console.error("Ошибка при обновлении токена:", error)
		}
	}
	const openAuthModal = () => {
		dispatch(setIsAuthOpen(true))
	}
	return (
		<div className={styles.menu}>
			<ul className={styles.list}>
				<li className={styles.list_section}>
					{isAuth ? (
						<>
							<Link
								to="/profile"
								className={styles.list_section}
								onClick={() =>
									dispatch(
										setActiveProfile(
											userData && userData.user_id
												? userData.user_id
												: 0,
										),
									)
								}>
								<div className={styles.profile_section}>
									<div className={styles.box_img}>
										{userData?.photo ? (
											<img
												className={styles.img_icon}
												src={photoLink}
												alt="icon_profile"
											/>
										) : (
											<img
												className={styles.img_icon}
												src={icon_profile}
												alt="icon_profile"
											/>
										)}
									</div>

									<span className={styles.prof_select}>
										{userData?.first_name && userData.last_name
											? `${userData.first_name} ${userData.last_name}`
											: "Имя Фамилия"}
									</span>
								</div>
							</Link>
							<li
								className={
									profiles.length > 1
										? styles.list_section
										: styles.none
								}>
								{profiles.length > 0 &&
									profiles
										.filter(
											(profile) =>
												profile.user_id !== userData?.user_id,
										)
										.map((profile, index) => (
											<div
												key={index}
												className={styles.profile_section}
												onClick={(event) => {
													event.preventDefault()
													handleProfileClick(profile)
												}}>
												<div className={styles.box_img}>
													{profile?.photo ? (
														<img
															className={styles.img_icon}
															src={`https://api.lr45981.tw1.ru${profile.photo}`}
															alt="icon_profile"
														/>
													) : (
														<img
															className={styles.img_icon}
															src={icon_profile}
															alt="icon_profile"
														/>
													)}
												</div>

												<span className={styles.prof_select}>
													{profile?.first_name && profile.last_name
														? `${profile.first_name} ${profile.last_name}`
														: "Имя Фамилия"}
												</span>
											</div>
										))}
							</li>
						</>
					) : (
						<span>Вы посетитель</span>
					)}
				</li>
				{!isAuth && (
					<li className={styles.list_section}>Стать пользователем</li>
				)}
				<Link to="/seller">
					<li className={styles.list_section}>Стать продавцом</li>
				</Link>
				{profiles.length < 2 && (
					<>
						<div className={styles.line}></div>
						<li
							onClick={openAuthModal}
							className={styles.list_section}>
							Добавить профиль
						</li>
					</>
				)}
				{/* <li className={styles.list_section}>
          {profiles.length > 0 &&
            profiles
              .filter(
                (profile) => profile.user_id !== userData?.user_id,
              )
              .map((profile, index) => (
                <div
                  key={index}
                  className={styles.profile_section}
                  onClick={(event) => {
                    event.preventDefault();
                    handleProfileClick(profile);
                  }}>
                  <div className={styles.box_img}>
                    {profile?.photo ? (
                      <img
                        className={styles.img_icon}
                        src={`https://api.lr45981.tw1.ru${profile.photo}`}
                        alt='icon_profile'
                      />
                    ) : (
                      <img
                        className={styles.img_icon}
                        src={icon_profile}
                        alt='icon_profile'
                      />
                    )}
                  </div>

                  <span className={styles.prof_select}>
                    {profile?.first_name && profile.last_name
                      ? `${profile.first_name} ${profile.last_name}`
                      : "Имя Фамилия"}
                  </span>
                </div>
              ))}
        </li> */}

				{isAuth && (
					<>
						<div className={styles.line}></div>
						<li className={styles.list_section} onClick={logOut}>
							Выйти из профиля
						</li>
					</>
				)}
			</ul>
		</div>
	)
}
