import { FC, useEffect, useState } from "react"
// Import images
import channel from "../../app/assets/profileCard/unknown_user.svg"
import comment from "../../../../../app/assets/lessons/comment.svg"
import lesson from "../../../../../app/assets/lessons/lesson.svg"
import more from "../../../../../app/assets/lessons/more_img.svg"
import play from "../../../../../app/assets/lessons/play.svg"
import star from "../../app/assets/lessons/star.svg"
import view from "../../../../../app/assets/lessons/view.svg"
import wallet from "../../../../../app/assets/lessons/wallet.svg"
import wallet1 from "../../app/assets/lessons/walletGreen.svg"
import styles from "./smallLessonCardElement.module.scss"
import { LessonProps } from "app/types/type"
import { Link } from "react-router-dom"
import { ShareMenu } from "components/LessonsUi/ShareMenu"
import { useDispatch, useSelector } from "app/service/hooks/hooks"
import { removeLessonFromBlacklist } from "app/service/lessons/lessonsSlice"
import editButton from "../../app/assets/lessons/EditButton.png"
import { BlackListModal } from "components/LessonsUi/BlackListModal/BlackListModal"
export const SmallLessonCardElement = ({}: // lessonData,
// profileData,
// currentId,
// onBlacklist,
LessonProps) => {
	const [showMenu, setShowMenu] = useState<boolean>(false)
	const [isPurchasedValid, setIsPurchasedValid] =
		useState<boolean>(true)
	const [activeLesson, setActiveLesson] = useState<number | null>(
		null,
	)

	const [openBlackListModal, setOpenBlackListModal] =
		useState<boolean>(false)
	const { blacklist } = useSelector((state) => state.lessons)
	const dispatch = useDispatch()
	const toggleMenu = (lessonId: number) => {
		setActiveLesson(lessonId)
		setShowMenu(!showMenu)
	}
	const closeMenu = () => setShowMenu(false)
	// const name =
	// 	profileData?.lat_first_name && profileData?.lat_last_name
	// 		? `${profileData.lat_first_name} ${profileData.lat_last_name}`
	// 		: "Имя Фамилия"

	const convertSecondsToMinutes = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60)
		const remainingSeconds = seconds % 60
		return `${minutes}:${remainingSeconds
			.toString()
			.padStart(2, "0")}`
	}
	// const publishedDate = new Date(
	// 	lessonData!.published_date,
	// ).toLocaleDateString("ru-RU", {
	// 	day: "2-digit",
	// 	month: "2-digit",
	// 	year: "numeric",
	// })
	// const purchaseDate = new Date(lessonData?.purchase_date!)

	// Устанавливаем дату окончания как покупка + 3 месяца
	// const extendedDate = new Date(purchaseDate)
	// extendedDate.setMonth(purchaseDate.getMonth() + 3)

	// useEffect(() => {
	// 	const currentDate = new Date()
	// 	const timeToCheck =
	// 		extendedDate.getTime() - currentDate.getTime() + 2000

	// 	const timeout = setTimeout(() => {
	// 		const now = new Date()
	// 		setIsPurchasedValid(now <= extendedDate)
	// 	}, timeToCheck)

	// 	// Очистка таймера при размонтировании компонента
	// 	return () => clearTimeout(timeout)
	// }, [extendedDate])

	// Форматируем дату окончания для отображения
	// const formattedExtendedDate = extendedDate.toLocaleDateString(
	// 	"ru-RU",
	// 	{
	// 		day: "2-digit",
	// 		month: "2-digit",
	// 		year: "numeric",
	// 	},
	// )

	let defaultUrl = "https://api.lr45981.tw1.ru"
	// let photoUrl = profileData?.photo
	// 	? defaultUrl + profileData.photo
	// 	: channel

	// let videoDuration = convertSecondsToMinutes(lessonData!.duration)

	// const isBlacklisted = blacklist.includes(lessonData?.id!)
	// const handleRemoveFromBlacklist = (lessonId: number) => {
	// 	dispatch(removeLessonFromBlacklist(lessonId))
	// }

	const closeBlackListModal = () => {
		setOpenBlackListModal(false)
	}
	return (
		<div
			className={
				// isBlacklisted ? styles.lessonBlacklist :
				styles.lesson
			}>
			{/* {!isBlacklisted ? ( */}
			{/* <> */}
			<div className={styles.imageContainer}>
				{/* <Link to={`/lesson/${lessonData?.id}`}> */}
				<img
					className={styles.img_lesson}
					src={lesson}
					alt="poster"
				/>

				<button className={styles.button_play}>
					<img
						className={styles.play_img}
						src={play}
						alt="playVideoIcon"
					/>
				</button>
				<div className={styles.time_box}>
					<div className={styles.time_lessons}>
						{/* {videoDuration} */}
						2:02
					</div>
				</div>
				{/* </Link> */}
			</div>
			<div className={styles.name_les_btn_share}>
				<p className={styles.name_les}>
					{/* {lessonData!.title} */}
					Название
				</p>
				<button
					className={styles.btn_share}
					// onClick={() => {
					// 	console.log("lessonData.id:", lessonData?.id)
					// 	toggleMenu(lessonData?.id!)
					// }}
				>
					<img
						className={styles.img_share}
						src={more}
						alt="ShareIcon"
					/>
				</button>
				{/* {currentId === lessonData?.user_id ? (
					<div className={styles.shareMenu}>
						{showMenu && activeLesson === lessonData?.id && (
							<ShareMenu
								closeMenu={closeMenu}
								myAccount={currentId === lessonData?.user_id}
							/>
						)}
					</div>
				) : ( */}
				<div className={styles.shareMenu}>
					{/* {showMenu && (
							// <ShareMenu
							// 	closeMenu={closeMenu}
							// 	myAccount={false}
							// 	lessonId={activeLesson!}
							// 	isFavouriteLesson={lessonData?.is_favorite}
							// />
						)} */}
				</div>
				{/* )} */}
			</div>
			<p className={styles.date}>
				Опубликовано:
				{/* {publishedDate} */}
				01.01.2002
			</p>
			<p className={styles.info}>
				{/* {lessonData!.description} */}
				Lorem ipsum dolor sit amet consectetur adipisicing elit.
				Voluptatem, aliquid cumque fugiat eum repellat totam odio ex
				perspiciatis doloribus dolorem quos pariatur quod rerum
				recusandae ab aut ullam perferendis in.
			</p>
			<div className={styles.channel_box}>
				{window.location.pathname === "/profile1" ? (
					<button className={styles.editButton}>Редактировать</button>
				) : (
					<>
						{/* <Link
							to={
								currentId === lessonData?.user_id
									? "/profile"
									: `/profile/${lessonData?.user_id}`
							}> */}
						<div className={styles.channel}>
							<img
								className={styles.img_channel}
								// src={photoUrl}
								alt="ChannelIcon"
							/>

							<div className={styles.name_view_com}>
								<p className={styles.name_channel}>
									<span>
										{/* {name} */}
										Dmitry Tomilin
									</span>
									{/* <img
                          className={styles.img_name}
                          src={star}
                          alt=''
                        /> */}
								</p>
								<div className={styles.view_comm_box}>
									<div className={styles.view_comment}>
										<img
											className={styles.img_view_com}
											src={view}
											alt=""
										/>
										<span className={styles.num_view_com}>
											{/* {lessonData?.views} */}
											1000
										</span>
									</div>
									<div className={styles.view_comment}>
										<img
											className={styles.img_view_com}
											src={comment}
											alt=""
										/>
										<span className={styles.num_view_com}>
											{/* {lessonData?.count_comments} */}1
										</span>
									</div>
								</div>
							</div>
						</div>
						{/* </Link> */}
					</>
				)}

				{/* {currentId !== lessonData?.user_id ? ( */}
				{/* // lessonData?.purchase_date !== null && isPurchasedValid ? ( */}
				{/* <button className={styles.extendDateButton}>
							<img className={styles.img_buy} src={wallet1} alt="" />
							<p className={styles.text_buy}> */}
				{/* до  01.01.2024 */}
				{/* {formattedExtendedDate} */}
				{/* </p>
						</button> */}
				{/* ) : ( */}
				<button className={styles.buy_btn}>
					<img className={styles.img_buy} src={wallet} alt="" />
					<p className={styles.text_buy}>
						Купить за
						<span>
							{/* {lessonData?.price}₽ */}
							123₽
						</span>
					</p>
				</button>
				{/* ) */}
				{/* ) : ( */}
				{/* <button className={styles.editButton}>Редактировать</button> */}
				{/* )} */}
			</div>
			{/* </> */}
			{/* ) : ( */}
			{/* <div className={styles.blackList}>
				<div className={styles.blackPhoto}>
					<span className={styles.text}>Урок удален</span> */}
			{/* <button
							className={styles.back}
							onClick={() =>
								handleRemoveFromBlacklist(lessonData?.id!)
							}>
							Отменить
						</button> */}
			{/* <button
						className={styles.addBlackList}
						onClick={() => setOpenBlackListModal(true)}>
						Расскажите почему
					</button>
				</div>
			</div> */}
			{/* // )} */}
			{/* // {openBlackListModal && ( */}
			{/* // 	<div className={styles.blackLiistModal}>
			// 		<BlackListModal
			// 			onClose={closeBlackListModal}
			// 			onBlacklist={onBlacklist}
			// 		/>
			// 	</div>
			// )} */}
		</div>
	)
}
