import { FC, useEffect, useState } from "react";
import styles from "./Home.module.scss";
import lessons from "../../app/assets/other/tutorials.svg";
import users from "../../app/assets/home/users.svg";
import { Loading } from "components/Loading/Loading";
import { TopContent } from "components/HomeComponent/TopContent/TopContent";
import { MainContent } from "components/HomeComponent/MainContent/MainContent";
import { Footer } from "components/Footer/Footer";
export const Home = () => {
  return (
    <div className={styles.wrapper}>
      <TopContent />
      <MainContent />
      {/* <Footer /> */}
    </div>
  );
};
