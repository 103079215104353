import {
  appendLessons,
  setFavouriteLessonsList,
  setIsFavourite,
  setLessons,
  setMyLessons,
  setProfileDataLessons,
  setUserLessons,
  setUsersProfiles,
} from "app/service/lessons/lessonsSlice";
import {
  setFollowers,
  setFollowing,
  setProfileData,
} from "app/service/profileCard/profileCardSlice";
import {
  IFollowers,
  IGetUsersProfiles,
  ILessonsState,
  IMyLessonsState,
  IProfileData,
} from "app/types/type";
import axios from "axios";
import { axiosWithRefreshToken } from "helpers/localStorage.helper";
import { toast } from "react-toastify";

export const BASEURL = "https://api.lr45981.tw1.ru";
export const XURL = "https://apiv.lr45981.tw1.ru";

//с токеном

export const getLessons = async (dispatch: any) => {
  try {
    const response = await axiosWithRefreshToken<ILessonsState>(
      `${XURL}/api/v1/lessons/homepage/`,
    );
    // const data: ILessonsState = response.data;
    dispatch(setLessons(response));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
export const loadMoreLessons = async (
  dispatch: any,
  next: string,
) => {
  try {
    console.log("Запрос отправлен на URL:", next);
    const response = await axiosWithRefreshToken<ILessonsState>(next);
    dispatch(appendLessons(response));
  } catch (error) {
    console.error(
      "Ошибка при загрузке дополнительных данных:",
      error,
    );
  }
};
export const getDataUserProfileWithOutToken = async (
  dispatch: any,
  id: string | number,
) => {
  try {
    // Получаем данные профиля из API
    const response = await axios.get(
      `${BASEURL}/api/v1/profiles/${id}/`,
    );

    // Извлекаем только поле `data` из ответа
    const profileData: IProfileData = response.data;

    // Диспатчим только полезные данные
    dispatch(setProfileData(profileData));

    dispatch(
      setProfileDataLessons({
        user_id: +id,
        profile: profileData,
      }),
    );
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const setIsFollow = async (id: string) => {
  try {
    await axiosWithRefreshToken<string>(
      `${BASEURL}/api/v1/profile/${id}/follow/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    toast.success("Вы подписались!");
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
// Анфолоу
export const setIsUnFollow = async (id: string) => {
  try {
    await axiosWithRefreshToken<string>(
      `${BASEURL}/api/v1/profile/${id}/unfollow/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    toast.success("Вы отписались!");
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getFollowers = async (dispatch: any, id: string) => {
  try {
    const data = await axiosWithRefreshToken<IFollowers>(
      `${BASEURL}/api/v1/profile/${id}/followers/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    dispatch(setFollowers(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getFollowing = async (dispatch: any, id: string) => {
  try {
    const data = await axiosWithRefreshToken<IFollowers>(
      `${BASEURL}/api/v1/profile/${id}/following/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    dispatch(setFollowing(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
// Получение уроков

export const getUsersLessons = async (
  dispatch: any,
  id: string,
  offset = 0,
  limit = 16,
) => {
  try {
    const data = await axiosWithRefreshToken<ILessonsState>(
      `${XURL}/api/v1/lessons/user/${id}/?limit=${limit}&offset=${offset}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    dispatch(setUserLessons(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
export const getMyLessons = async (
  dispatch: any,
  offset = 0,
  limit = 16,
) => {
  try {
    const data = await axiosWithRefreshToken<IMyLessonsState>(
      `${XURL}/api/v1/lessons/my-lessons/?limit=${limit}&offset=${offset}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    dispatch(setMyLessons(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const addFavouriteLesson = async (
  dispatch: any,
  id: number,
) => {
  try {
    const data = await axiosWithRefreshToken<{ detail: string }>(
      `${XURL}/api/v1/lesson/${id}/favorite/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    toast.success("Урок добавлен в избранное");

    dispatch(setIsFavourite(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
export const deleteFavouriteLesson = async (id: number) => {
  try {
    await axiosWithRefreshToken<string>(
      `${XURL}/api/v1/lesson/${id}/unfavorite/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    toast.success("Урок убран из избранного");
    // dispatch(setUserLessons(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getFavouriteLessonsList = async (dispatch: any) => {
  try {
    const data = await axiosWithRefreshToken<ILessonsState>(
      `${XURL}/api/v1/lessons/favorites/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    dispatch(setFavouriteLessonsList(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getUsersProfileList = async (
  dispatch: any,
  ids: string,
) => {
  try {
    const data = await axiosWithRefreshToken<IGetUsersProfiles[]>(
      `${BASEURL}/api/v1/profiles/by-user-ids/`,
      {
        params: { user_ids: ids },
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    dispatch(setUsersProfiles(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const addLessonInBlackList = async (
  dispatch: any,
  id: number,
) => {
  try {
    await axiosWithRefreshToken<IGetUsersProfiles[]>(
      `${XURL}/api/v1/lesson/${id}/blacklist/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

// Запросы без токена

// Без токена
export const getLessonsWithOutToken = async (dispatch: any) => {
  try {
    const response = await axios.get(
      `${XURL}/api/v1/lessons/homepage/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const data: ILessonsState = response.data;
    dispatch(setLessons(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getUsersProfileListWithOutToken = async (
  dispatch: any,
  ids: string,
) => {
  try {
    const data = await axios.get(
      `${BASEURL}/api/v1/profiles/by-user-ids/`,
      {
        params: { user_ids: ids },
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const response: IGetUsersProfiles[] = data.data;
    dispatch(setUsersProfiles(response));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getFavouriteLessonsListWithOutToken = async (
  dispatch: any,
) => {
  try {
    const data = await axios.get(`${XURL}/api/v1/lessons/favorites/`);
    const response: ILessonsState = data.data;
    dispatch(setFavouriteLessonsList(response));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getUsersLessonsWhitOutToken = async (
  dispatch: any,
  id: string,
) => {
  try {
    const data = await axios.get(
      `${XURL}/api/v1/lessons/user/${id}/`,
    );
    const response: ILessonsState = data.data;
    dispatch(setUserLessons(response));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getFollowersWithOutToken = async (
  dispatch: any,
  id: string,
) => {
  try {
    const data = await axios.get(
      `${BASEURL}/api/v1/profile/${id}/followers/`,
    );
    const response: IFollowers = data.data;
    dispatch(setFollowers(response));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getFollowingWithOutToken = async (
  dispatch: any,
  id: string,
) => {
  try {
    const data = await axios.get(
      `${BASEURL}/api/v1/profile/${id}/following/`,
    );
    const response: IFollowers = data.data;
    dispatch(setFollowing(response));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
