import React, { useState } from "react";
import s from "./style.module.scss";
import closeIcon from "../../../app/assets/confirmEmailModal/closeModalIcon.svg";
import checkedIcon from "../../../app/assets/other/checkedIcon.svg";

export const BlackListModal = ({
  onClose,
  onBlacklist,
}: {
  onClose: () => void;
  onBlacklist: any;
}) => {
  const [text, setText] = useState<string>("");
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const handleTextareaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setText(e.target.value);
  };
  console.log(onBlacklist);
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.closeButtonContainer}>
          <button onClick={() => onClose()} className={s.closeIcon}>
            <img src={closeIcon} alt='closeModalIcon' />
          </button>
        </div>

        <section>
          <h1 className={s.title}>РАССКАЖИТЕ ПОЧЕМУ</h1>
        </section>
        <p className={s.text}>
          <span>
            Нам очень важно ваше мнение почему вас не интересует этот
            урок, что вам не понравилось
          </span>
        </p>
        <div className={s.inputBlock}>
          <textarea
            className={s.textarea}
            onChange={handleTextareaChange}
            value={text}
            maxLength={100}
          />
          <span className={s.length}>{text.length}/100</span>
        </div>

        <div className={s.rules}>
          <button
            type='button'
            className={isAgree ? s.checked : s.buttonCheckbox}
            onClick={() => setIsAgree(!isAgree)}></button>
          <p>
            Я даю согласие на{" "}
            <span className={s.gradientText}>
              обработку своих персональных данных
            </span>{" "}
            и{" "}
            <span className={s.gradientText}>
              принимаю условия оферты
            </span>
          </p>
          {isAgree && (
            <img
              onClick={() => setIsAgree(!isAgree)}
              src={checkedIcon}
              alt='checkedIcon'
              className={s.checkedIcon}
            />
          )}
        </div>
        <button
          className={isAgree && text.length > 0 ? s.sendButton : s.notActive}
          onClick={
            isAgree && text.length > 1 ? onBlacklist : () => ""
          }>
          Отправить запрос на проверку
        </button>
      </div>
    </div>
  );
};
