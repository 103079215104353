import { FC, useState } from "react"
import { Bounce, toast } from "react-toastify"

import { AuthService } from "../../app/service/servise"
import { login, setUserData } from "../../app/service/user/userSlice"
import styles from "./AuthFrm.module.scss"

// Import img
import { AuthForm as AuthFormType } from "../../app/types/modal"
import cross from "./cross.svg"
import { useDispatch, useSelector } from "app/service/hooks/hooks"
import { IGetUserData, IUser } from "app/types/type"
import checkedIcon from "../../app/assets/other/checkedIcon.svg"
import { useLocation, useNavigate } from "react-router"
import {
	axiosWithRefreshToken,
	refreshTokenForBurger,
} from "helpers/localStorage.helper"
import { ConfirmEmailModal } from "components/ConfirmEmailModal"
import {
	setConfirmPassword,
	setEmail,
	setIsChecked,
	setIsConfirmEmail,
	setIsLogin,
	setPassword,
	setTelegram,
} from "app/service/auth/authSlice"
import {
	addProfile,
	setActiveProfile,
	updateProfile,
} from "app/service/burgerProfiles/burgerSlice"
import { setIsAuthOpen } from "app/service/navigationModals/NavigationModalsSlice"
export const AuthForm = () => {
	const {
		email,
		telegram,
		password,
		confirmPassword,
		isLogin,
		isConfirmEmail,
		isChecked,
	} = useSelector((state) => state.auth)
	const { profiles } = useSelector((state) => state.burgerProfiles)
	const { isModalOpen, isAuthOpen } = useSelector(
		(state) => state.navigationModal,
	)
	console.log(isAuthOpen)
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()

	const getDataUser = async () => {
		try {
			const data = await axiosWithRefreshToken<IGetUserData>(
				"https://api.lr45981.tw1.ru/api/v1/profile/my-profile/",
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				},
			)

			dispatch(setUserData(data))
			dispatch(addProfile(data))
			return data
		} catch (error) {
			console.error("Ошибка при получении данных:", error)
		}
	}

	if (!isAuthOpen) return null
	const closeModal = () => {
		navigate("/")
		dispatch(setIsAuthOpen(false))
		if (isConfirmEmail) {
			dispatch(setIsConfirmEmail(false))
		}
	}
	const toggleForm = () => {
		dispatch(setIsLogin(!isLogin))
	}

	const registrationHandler = async (
		e: React.FormEvent<HTMLFormElement>,
	) => {
		e.preventDefault()
		if (password !== confirmPassword) {
			toast.error("Пароли не совпадают")
			console.log(password.valueOf())
			return
		}
		try {
			const data = await AuthService.registration({
				email,
				password,
				telegram,
			})
			if (data) {
				localStorage.setItem("email", data.email)
				toast.success("Аккаунт успешно создан,проверьте почту")
				dispatch(setIsConfirmEmail(true))
			}
		} catch (error: any) {
			const err = error.response.data.email
			console.log(err)
			toast.error(
				err?.toString() || "Ошибка при создании пользователя",
				{
					position: "top-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					transition: Bounce,
				},
			)
		}
	}

	const loginHandler = async (
		e: React.FormEvent<HTMLFormElement>,
	) => {
		e.preventDefault()
		try {
			const data = await AuthService.login({
				email,
				password,
			})
			if (data) {
				localStorage.setItem("accessToken", data.access)
				localStorage.setItem("refreshToken", data.refresh)

				const from = location.state?.from?.pathname || "/"
				navigate(from)
				const user: IUser = { email, password }
				dispatch(login(user))
				toast.success("Ваш вход успешен")
				const profileData = await getDataUser()
				localStorage.setItem(
					`accessToken_${profileData?.user_id}`,
					data.access,
				)
				localStorage.setItem(
					`refreshToken_${profileData?.user_id}`,
					data.refresh,
				)
				if (profileData?.user_id) {
					localStorage.setItem(
						`activeAcc_${profileData?.user_id}`,
						profileData?.user_id.toString(),
					)
				}

				dispatch(setIsAuthOpen(false))
			}
		} catch (error: any) {
			const err = error.response?.data.message
			toast.error(err ? err.toString() : "Ошибка при входе", {
				position: "top-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				transition: Bounce,
			})
		}
	}
	const errorHandler = (e: any) => [
		e.preventDefault(),
		toast.error(
			"Вы не дали согласие на обработку персональных данных ",
			{
				position: "top-left",
			},
		),
	]
	console.log(profiles)
	const refresh = localStorage.getItem("refreshToken")

	const handleAddProfile = async (
		e: React.FormEvent<HTMLFormElement>,
	) => {
		if (profiles.length === 0) {
			// Вызываем loginHandler, если профилей нет
			await loginHandler(e)
		} else {
			e.preventDefault()
			// Если профили уже есть, выполняем refreshToken() и получаем данные профиля
			try {
				const data = await AuthService.login({
					email,
					password,
				})
				if (data) {
					localStorage.setItem("accessToken", data.access)
					localStorage.setItem("refreshToken", data.refresh)
					const user: IUser = { email, password }
					dispatch(login(user))
					// await refreshTokenForBurger(refresh ? refresh : "");
					toast.success("Ваш вход успешен")
					const profileData = await getDataUser() // Предположим, что getDataUser() возвращает данные профиля
					localStorage.setItem(
						`accessToken_${profileData?.user_id}`,
						data.access,
					)
					localStorage.setItem(
						`refreshToken_${profileData?.user_id}`,
						data.refresh,
					)
					// Обновляем профиль в Redux
					if (profileData) {
						dispatch(updateProfile(profileData))
						dispatch(setActiveProfile(profileData.user_id))
					}

					dispatch(setIsAuthOpen(false))
				}
			} catch (error) {
				console.error("Ошибка обновления токена:", error)
			}
		}
	}

	return (
		<div className={styles.authWrapper}>
			<div
				className={isConfirmEmail ? styles.hidden : styles.box_auth}>
				<button onClick={closeModal} className={styles.closeModal}>
					<img src={cross} alt="close" />
				</button>
				<form
					onSubmit={
						!isLogin && !isChecked
							? errorHandler
							: isLogin
							? handleAddProfile
							: registrationHandler
					}
					className={styles.form}>
					<h1 className={styles.title}>
						{isLogin ? "ВХОД" : "РЕГИСТРАЦИЯ"}
					</h1>
					<div className={styles.inputs}>
						<input
							onChange={(e) => dispatch(setEmail(e.target.value))}
							id="email"
							className={styles.input_auth}
							placeholder="Почта"
							type="text"
							value={email}
						/>
						{!isLogin && (
							<input
								onChange={(e) =>
									dispatch(setTelegram(e.target.value))
								}
								id="username"
								className={styles.input_auth}
								placeholder="Телеграмм"
								type="text"
								value={telegram}
							/>
						)}
						<input
							className={styles.input_auth}
							id="password"
							type="password"
							onChange={(e) => dispatch(setPassword(e.target.value))}
							placeholder="Пароль"
							value={password}
						/>
						{!isLogin && (
							<input
								className={styles.input_auth}
								placeholder="Повторить пароль"
								type="password"
								onChange={(e) =>
									dispatch(setConfirmPassword(e.target.value))
								}
								value={confirmPassword}
							/>
						)}
					</div>
					{isLogin && (
						<button className={styles.reset_pass} type="button">
							Забыли пароль?
						</button>
					)}
					{!isLogin && (
						<div className={styles.rules}>
							<button
								type="button"
								className={
									isChecked ? styles.checked : styles.buttonCheckbox
								}
								onClick={() =>
									dispatch(setIsChecked(!isChecked))
								}></button>
							<p>
								Я даю согласие на{" "}
								<span className={styles.gradientText}>
									обработку своих персональных данных
								</span>{" "}
								и{" "}
								<span className={styles.gradientText}>
									принимаю условия оферты
								</span>
							</p>
							{isChecked && (
								<img
									onClick={() => dispatch(setIsChecked(!isChecked))}
									src={checkedIcon}
									alt="checkedIcon"
									className={styles.checkedIcon}
								/>
							)}
						</div>
					)}

					<button
						className={
							isChecked && !isLogin
								? styles.send_form
								: isLogin
								? styles.send_form
								: styles.nonActive
						}>
						{isLogin ? "Войти" : "Зарегистрироваться"}
					</button>
					<p className={styles.question}>
						{isLogin ? "У вас нет аккаунта?" : "Уже есть аккаунт?"}
						<button
							type="button"
							onClick={toggleForm}
							className={styles.login_or_reg}>
							{isLogin ? "Зарегистрироваться" : "Войти"}
						</button>
					</p>
				</form>
			</div>
			{isConfirmEmail && (
				<ConfirmEmailModal closeModal={closeModal} />
			)}
		</div>
	)
}
