import styles from "./Users.module.scss";
// Import image
import location from "../../app/assets/users/location.svg";
import user from "../../app/assets/users/motinova.png";
import video from "../../app/assets/other/tutorialsBlackIcon.svg";
import view from "../../app/assets/users/view.svg";
import { IFollower, IPropsUsers } from "app/types/type";
import { useEffect, useState } from "react";
import { getCounterProfile } from "app/api/api";
import { useDispatch, useSelector } from "app/service/hooks/hooks";

export const User = ({
  userData,
  countLessons,
  totalViews,
}: IPropsUsers) => {
  const userDataUrl = userData.photo
    ? "https://api.lr45981.tw1.ru" + userData.photo
    : null;

  const [photo, setPhoto] = useState<string>(user); // Default photo

  useEffect(() => {
    const isAbsoluteUrl = userData.photo?.startsWith("http");

    setPhoto(
      isAbsoluteUrl ? userData.photo || "" : userDataUrl || user,
    );
  }, [userData, userDataUrl]);

  return (
    <div className={styles.box_user}>
      <img className={styles.user_img} src={photo} alt='user_photo' />
      <div className={styles.line}></div>
      <div className={styles.container}>
        <p className={styles.name}>
          {userData.first_name && userData.last_name
            ? `${userData.first_name} ${userData.last_name}`
            : "Имя Фамилия"}
        </p>
        <p className={styles.location}>
          <img
            className={styles.image_location}
            src={location}
            alt='location_img'
          />
          {userData.country && userData.city
            ? `${userData.country} ${userData.city}`
            : "Страна, Город"}
        </p>
        <div className={styles.view_video}>
          <div className={styles.view}>
            <img src={view} alt='view_img' />
            <span className={styles.num}>{totalViews}</span>
          </div>
          <div className={styles.video}>
            <img src={video} alt='video_img' />
            <span className={styles.num}> {countLessons}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
