import React, { useEffect, useState } from "react";
import s from "./style.module.scss";
import rightSideImg from "../../../app/assets/lessons/RightSideImg.jpg";
import more from "../../../app/assets/lessons/more_img.svg";
import view from "../../../app/assets/lessons/view.svg";
import comment from "../../../app/assets/lessons/comment.svg";
import wallet from "../../../app/assets/lessons/wallet.svg";
import { LessonProps } from "app/types/type";
import { Loading } from "components/Loading/Loading";
import channel from "../../../app/assets/profileCard/unknown_user.svg";
import { Link } from "react-router-dom";
export const RightSideVideo = ({
  lessonData,
  profileData,
}: LessonProps) => {
  const [isPurchasedValid, setIsPurchasedValid] =
    useState<boolean>(true);
  const name =
    profileData?.lat_first_name && profileData?.lat_last_name
      ? `${profileData.lat_first_name} ${profileData.lat_last_name}`
      : "Имя Фамилия";

  const convertSecondsToMinutes = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  let videoDuration = convertSecondsToMinutes(lessonData!.duration);
  const publishedDate = new Date(
    lessonData!.published_date,
  ).toLocaleDateString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  let defaultUrl = "https://api.lr45981.tw1.ru";
  let photoUrl = profileData?.photo
    ? defaultUrl + profileData.photo
    : channel;

  const purchaseDate = new Date(lessonData?.purchase_date!);
  console.log(profileData);
  // Устанавливаем дату окончания как покупка + 3 месяца
  const extendedDate = new Date(purchaseDate);
  extendedDate.setMonth(purchaseDate.getMonth() + 3);

  useEffect(() => {
    const currentDate = new Date();
    const timeToCheck =
      extendedDate.getTime() - currentDate.getTime() + 2000;

    const timeout = setTimeout(() => {
      const now = new Date();
      setIsPurchasedValid(now <= extendedDate);
    }, timeToCheck);

    // Очистка таймера при размонтировании компонента
    return () => clearTimeout(timeout);
  }, [extendedDate]);

  // Форматируем дату окончания для отображения
  const formattedExtendedDate = extendedDate.toLocaleDateString(
    "ru-RU",
    {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
  );

  return (
    <div className={s.wrapper}>
      {!lessonData ? (
        <Loading />
      ) : (
        <>
          <div className={s.imageContainer}>
            <img
              src={lessonData!.poster_url}
              alt='rightSideImg'
              className={s.rightSideImg}
            />
            <div className={s.timebox}>
              <span className={s.time}>{videoDuration}</span>
            </div>
          </div>
          <div className={s.container}>
            <section className={s.titleBlock}>
              <h1 className={s.title}>{lessonData?.title}</h1>
              <img
                className={s.img_share}
                src={more}
                alt='ShareIcon'
              />
            </section>
            <p>
              <span className={s.date}>
                Опубликовано: {publishedDate}
              </span>
              <span className={s.description}>
                {lessonData?.description}
              </span>
            </p>
            <div className={s.bottomContainer}>
              <Link to={`/profile/${lessonData?.user_id}`}>
                <div className={s.profileContainer}>
                  <span className={s.round}>
                    <img src={photoUrl} alt='profilePhoto' />
                  </span>
                  <div className={s.profile}>
                    <h2 className={s.name}>{name}</h2>
                    <div className={s.statistic}>
                      <span>
                        <img src={view} alt='view' />
                        {lessonData.views}
                      </span>
                      <span>
                        <img src={comment} alt='comment' />
                        {lessonData.count_comments}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>

              <button className={s.buy_btn}>
                <img className={s.img_buy} src={wallet} alt='' />
                <span className={s.text_buy}>
                  Купить за {lessonData.price} ₽
                </span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
