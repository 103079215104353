import React, { useEffect, useState } from "react"
import s from "./style.module.scss"
import img from "../../app/assets/lessons/img.svg"
import share from "../../app/assets/lessons/shareBlack.svg"
import notIntresting from "../../app/assets/lessons/NotShowIcon.svg"
import favourite from "../../app/assets/lessons/favouriteIconBlack.svg"
import complaint from "../../app/assets/lessons/complaintBlack.svg"
import unFavourite from "../../app/assets/lessons/unFavouriteIconBlack.svg"
import view from "../../app/assets/lessons/view.svg"
import comment from "../../app/assets/lessons/comment.svg"
import search from "../../app/assets/lessons/search.svg"
import wallet from "../../app/assets/lessons/wallet.svg"
import { CustomFilter } from "components/LessonsUi/CustomFilter/CustomFilter"
import Player from "@kinescope/react-kinescope-player"
import { useDispatch, useSelector } from "app/service/hooks/hooks"
import { useParams } from "react-router"
import {
	getPlayerComments,
	getPlayerId,
	getPlayerIdWithOutToken,
	sendPlayerComment,
} from "app/api/apiPlayer"
import { RightSideVideo } from "components/PlayerUi/RightSideVideo/RightSideVideo"
import { Comment } from "components/PlayerUi/Comment/Comment"
import { jwtDecode, JwtPayload } from "jwt-decode"
import {
	addFavouriteLesson,
	addLessonInBlackList,
	deleteFavouriteLesson,
	getLessons,
	getUsersProfileList,
	getUsersProfileListWithOutToken,
	setIsFollow,
	setIsUnFollow,
} from "app/api/apiLessons"
import { setFavouriteLessonsList } from "app/service/lessons/lessonsSlice"
import {
	ILesson,
	IPlayerCommentDataResults,
	IUsersProfiles,
} from "app/types/type"
import { getDataUserProfile } from "app/api/api"
import { Loading } from "components/Loading/Loading"
import { Link } from "react-router-dom"
import { setSubscribe } from "app/service/profileCard/profileCardSlice"
import { toast } from "react-toastify"
import { AdaptiveCardVideo } from "components/LessonsUi/AdaptiveCardVideo/AdaptiveCardVideo"
import { AddVideoCard } from "components/PlayerUi/AddVideoCard/AddVideoCard"
export const LessonPlayer = () => {
	const dispatch = useDispatch()
	const { playerData, playerCommentData } = useSelector(
		(state) => state.player,
	)
	const { favouriteLessonsList } = useSelector(
		(state) => state.lessons,
	)

	interface CustomJwtPayload extends JwtPayload {
		email: string
	}
	const { isAuth, userData } = useSelector((state) => state.user)
	const { profileData, subscribe } = useSelector(
		(state) => state.profileCard,
	)

	const {
		usersProfiles,
		lessons,
		blacklist,
		activeFilter,
		myLessons,
	} = useSelector((state) => state.lessons)
	const { idLesson } = useParams()
	const [isFavouriteLessonId, setIsFavouriteLessonId] = useState<
		boolean | null
	>(null)

	const [loading, setLoading] = useState(false)
	const [hasFetchedPlayerId, setHasFetchedPlayerId] = useState(false)
	const [hasFetchedProfile, setHasFetchedProfile] = useState(false)
	const [hasFetchedComments, setHasFetchedComments] = useState(false)
	const [value, setValue] = useState("")
	const [isFocused, setIsFocused] = useState(false)
	const [blacklistedLessons, setBlacklistedLessons] = useState<
		number[]
	>([])

	const handleAddToBlacklist = (lessonId: number) => {
		addLessonInBlackList(dispatch, lessonId)
		setBlacklistedLessons((prev) => [...prev, lessonId])
		toast.success("Видео  убрано из рекомендаций")
	}

	function handleChange(
		event: React.ChangeEvent<HTMLTextAreaElement>,
	) {
		setValue(event.target.value)
	}

	useEffect(() => {
		if (playerData?.is_favorite !== undefined) {
			setIsFavouriteLessonId(playerData.is_favorite)
		}
	}, [playerData?.is_favorite])

	const token = localStorage.getItem("accessToken")
	let emailToken
	if (token) {
		try {
			// Декодируем токен
			const decodedToken: CustomJwtPayload = jwtDecode(token)
			emailToken = decodedToken.email
		} catch (error) {
			console.error("Ошибка при декодировании токена:", error)
		}
	} else {
		console.log("Токен не найден в localStorage")
	}

	// Первый useEffect для загрузки основной информации (плеера, комментариев и т.д.)
	useEffect(() => {
		if (idLesson) {
			const fetchData = async () => {
				setLoading(true)

				try {
					if (isAuth) {
						if (!hasFetchedPlayerId) {
							await getPlayerId(dispatch, idLesson.toString())
							setHasFetchedPlayerId(true)
						}

						if (playerData?.user_id && !hasFetchedProfile) {
							await getDataUserProfile(
								dispatch,
								playerData.user_id.toString(),
							)
							setHasFetchedProfile(true)
						}

						if (!hasFetchedComments) {
							await getPlayerComments(dispatch, +idLesson)
							setHasFetchedComments(true)
						}
					} else {
						if (!hasFetchedPlayerId) {
							await getPlayerIdWithOutToken(
								dispatch,
								idLesson.toString(),
							)
							setHasFetchedPlayerId(true)
						}
					}
				} catch (error) {
					console.error("Ошибка при загрузке данных:", error)
				} finally {
					setLoading(false)
				}
			}

			fetchData()
		} else {
			console.log("ID не найден")
		}
	}, [dispatch, loading, hasFetchedPlayerId, playerData?.user_id])

	// Второй useEffect для загрузки профилей комментаторов после завершения первого
	useEffect(() => {
		const fetchCommentProfiles = async () => {
			if (hasFetchedComments && playerCommentData?.results) {
				// Извлекаем уникальные user_id комментаторов
				const uniqueUserIds = new Set<number>()
				playerCommentData.results.forEach(
					(comment: IPlayerCommentDataResults) => {
						uniqueUserIds.add(comment.user_id!)
					},
				)

				// Запрашиваем профили для уникальных user_id
				const userIdArray = Array.from(uniqueUserIds)
				if (userIdArray.length > 0) {
					const idsString = userIdArray.join(",")
					await getUsersProfileList(dispatch, idsString)
				}
			}
		}

		fetchCommentProfiles()
	}, [dispatch])
	useEffect(() => {
		if (profileData) {
			console.log(profileData)
			dispatch(setSubscribe(profileData.is_subscribed!))
		}
	}, [profileData])

	const handleFollow = async () => {
		try {
			await setIsFollow(
				playerData?.user_id ? playerData?.user_id.toString() : "",
			)
			dispatch(setSubscribe(true))
		} catch (error) {
			toast.error("Ошибка при подписке.")
		}
	}

	const handleUnfollow = async () => {
		try {
			await setIsUnFollow(
				playerData?.user_id ? playerData?.user_id.toString() : "",
			)
			dispatch(setSubscribe(false))
		} catch (error) {
			toast.error("Ошибка при отписке.")
		}
	}

	const addFavouriteVideo = async () => {
		if (idLesson) {
			await addFavouriteLesson(dispatch, +idLesson)
			setIsFavouriteLessonId(true)
		}
	}
	const handleDeleteLesson = async () => {
		try {
			if (idLesson) {
				await deleteFavouriteLesson(+idLesson)
			}
			setIsFavouriteLessonId(false)
			if (favouriteLessonsList?.results && idLesson) {
				dispatch(
					setFavouriteLessonsList({
						...favouriteLessonsList,
						results: favouriteLessonsList.results.filter(
							(lesson: ILesson) => lesson.id !== +idLesson,
						),
					}),
				)
			}
		} catch (error) {
			console.error("Ошибка при удалении урока из избранного:", error)
		}
	}

	const nameProfile =
		profileData?.lat_first_name && profileData.lat_last_name
			? `${profileData?.lat_first_name}  ${profileData.lat_last_name}`
			: "Имя Фамиля"

	const [playerSize, setPlayerSize] = useState({
		width: "100%",
		height: "636",
	})

	const handleSizeChange = ({
		width,
		height,
	}: {
		width: number
		height: number
	}) => {
		setPlayerSize({
			width: width < 600 ? `${width}px` : "100%",
			height: height < 400 ? `${height}px` : `${height}px`,
		})
	}
	function autoGrow(el: any) {
		el.style.height = "5px"
		el.style.height = el.scrollHeight + "px"
	}

	const textarea = document.getElementById("textarea")
	if (textarea) {
		textarea.addEventListener("input", function () {
			autoGrow(this as HTMLElement)
		})
	}

	const handleSendComment = (id: number, text: string) => {
		sendPlayerComment(id, text)
		setValue("")
		setTimeout(() => {
			getPlayerComments(dispatch, id)
		}, 500)
	}
	const filteredLessons = (lessons?.results ?? [])
		.filter(
			(lesson: ILesson) =>
				lesson.user_id !== userData?.user_id &&
				(idLesson === undefined || lesson.id !== +idLesson),
		)
		.sort((a: ILesson, b: ILesson) => {
			if (activeFilter === "popularity") {
				return b.views - a.views
			} else if (activeFilter === "date") {
				return (
					new Date(b.published_date).getTime() -
					new Date(a.published_date).getTime()
				)
			} else if (activeFilter === "topic") {
				return a.title.localeCompare(b.title)
			}
			return 0
		})
	useEffect(() => {
		const fetchLessons = async () => {
			setLoading(true)
			try {
				await getLessons(dispatch) // Запрос первого набора уроков
			} catch (error) {
				console.error("Ошибка при загрузке уроков:", error)
			} finally {
				setLoading(false)
			}
		}

		fetchLessons()
	}, [dispatch])
	useEffect(() => {
		const fetchProfiles = async () => {
			if (!loading && lessons?.results) {
				const uniqueUserIds = new Set<number>()
				lessons.results.forEach((lesson: ILesson) => {
					uniqueUserIds.add(lesson?.user_id!)
				})
				const userIdArray = Array.from(uniqueUserIds)

				if (userIdArray.length === 0) return
				const idsString = userIdArray.join(",")
				if (isAuth) {
					getUsersProfileList(dispatch, idsString)
				} else {
					getUsersProfileListWithOutToken(dispatch, idsString)
				}
			}
		}
		fetchProfiles()
	}, [lessons, loading, dispatch]) // Запускаем только при изменении уроков
	const profilesMap = Array.isArray(usersProfiles)
		? usersProfiles.reduce(
				(
					acc: Record<number, IUsersProfiles>,
					profile: IUsersProfiles,
				) => {
					acc[profile.user_id] = profile
					return acc
				},
				{},
		  )
		: {}
	console.log(filteredLessons.length)
	return (
		<div className={s.wrapper}>
			{loading || !hasFetchedProfile ? (
				<Loading />
			) : (
				<div className={s.container}>
					<div className={s.inputContainer}>
						<div className={s.inputBlock}>
							<div className={s.search}>
								<img className={s.vol} src={img} alt="voiceSearch" />
								<input
									className={s.input}
									placeholder="Поиск"
									type="text"
								/>
								<img
									className={s.search_img}
									src={search}
									alt="SearchIcon"
								/>
							</div>
						</div>
						<div className={s.filterBlock}>
							<CustomFilter threeTab={true} />
						</div>
					</div>
					<main className={s.main}>
						<div className={s.playerContainer}>
							<div className={s.player}>
								{playerData?.media_id ? (
									<>
										{/* <div className={s.playerWrapper}> */}
										{/* <div className={s.responsiveContainer}> */}
										<div className={s.kinPlayerEmbedContainer}>
											<Player
												videoId={playerData.media_id}
												onSizeChanged={handleSizeChange}
												watermark={{
													text: `${emailToken}`,
													position: "bottom-right",
													opacity: 1,
													floating: true,
												}}
											/>
										</div>

										{/* <iframe
                        src={`https://kinescope.io/embed/${playerData.media_id}`}
                        title='Kinescope Player'
                        allow='autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;'
                        frameBorder='0'
                        allowFullScreen
                        ></iframe> */}
										{/* </div> */}
										{/*</div> */}
									</>
								) : (
									<div className={s.posterContainer}>
										<div className={s.background}></div>
										<img
											src={playerData?.poster_url}
											className={s.poster}
											alt="posterImg"
										/>
										<button className={s.buyBtnPoster}>
											<img
												className={s.img_buy}
												src={wallet}
												alt="wallet"
											/>
											<span>
												Купить за{" "}
												{playerData?.price ? playerData?.price : 0}₽
											</span>
										</button>
									</div>
								)}
							</div>
							<div className={s.videoInfoBlock}>
								<section>
									<h1 className={s.titleVideo}>
										{playerData?.title
											? playerData.title
											: "Название урока"}
									</h1>
								</section>
								<div
									className={
										profileData?.user_id === userData?.user_id
											? s.navigationProfileBlock
											: s.navigationProfileBlockOwner
									}>
									<div className={s.profileContainer}>
										<Link
											to={
												userData?.user_id === playerData?.user_id
													? `/profile`
													: `/profile/${playerData?.user_id}`
											}>
											<div className={s.profile}>
												<span className={s.round}>
													<img
														src={
															"https://api.lr45981.tw1.ru" +
															profileData?.photo
														}
														alt="profilePhoto"
													/>
												</span>
												<div className={s.profileInfo}>
													<h2 className={s.profileName}>
														{nameProfile}
													</h2>
													<span className={s.date}>
														{playerData?.published_date
															? new Date(
																	playerData.published_date,
															  ).toLocaleDateString("ru-RU", {
																	day: "2-digit",
																	month: "long",
																	year: "numeric",
															  })
															: " 01.01.2024"}
														id-{idLesson}
													</span>
												</div>
											</div>
										</Link>

										{profileData?.user_id !== userData?.user_id ? (
											subscribe ? (
												<button
													className={s.isSubscribe}
													onClick={handleUnfollow}>
													Вы подписаны
												</button>
											) : (
												<button
													className={s.subscribe}
													onClick={handleFollow}>
													Подписаться
												</button>
											)
										) : (
											<button className={s.editButton}>
												Редактировать
											</button>
										)}
									</div>
									<nav
										className={
											profileData?.user_id !== userData?.user_id
												? s.navigationButtons
												: s.oneNavigationButton
										}>
										{profileData?.user_id !== userData?.user_id &&
											(isFavouriteLessonId ? (
												<button
													className={s.button}
													onClick={handleDeleteLesson}>
													<img
														src={unFavourite}
														alt="unfavouriteIcon"
													/>
													<span>Убрать</span>
												</button>
											) : (
												<button
													className={s.button}
													onClick={addFavouriteVideo}>
													<img src={favourite} alt="favouriteIcon" />
													<span>В избранное</span>
												</button>
											))}
										<button className={s.button}>
											<img src={share} alt="ShareIcon" />
											Поделиться
										</button>
										{profileData?.user_id !== userData?.user_id && (
											<button className={s.button}>
												<img src={complaint} alt="complaintIcon" />
												Пожаловаться
											</button>
										)}
									</nav>
								</div>
								<div className={s.statistic}>
									<span>
										<img src={view} alt="view" />
										{playerData?.views ? playerData.views : 0}
									</span>
									<span>
										<img src={comment} alt="comment" />
										{playerData?.count_comments
											? playerData.count_comments
											: 0}
									</span>
								</div>
								<p className={s.description}>
									{playerData?.description
										? playerData.description
										: "описание"}
								</p>
							</div>

							<div className={s.commentsContainer}>
								<h1 className={s.commentsTitle}>Комментарии</h1>
								{playerData?.media_id && (
									<div className={s.CommentWrapper}>
										<div className={s.commentFieldContainer}>
											<textarea
												name="comment"
												id="textarea"
												placeholder="Оставить комментарий"
												onChange={handleChange}
												onFocus={() => setIsFocused(true)}
												value={value}
												className={s.commentsField}
												maxLength={200}
											/>
											<button
												className={s.sendComment}
												onClick={() =>
													handleSendComment(+idLesson!, value)
												}>
												Отправить
											</button>
											<span className={s.counterLetter}>
												{value.length > 0
													? `${value.length} / 200`
													: "0 / 200"}
											</span>
										</div>
										{/* {isFocused && (
                      <div className={s.sendCommentContainer}>
                        <button
                          className={s.sendComment}
                          onClick={() => setIsFocused(false)}>
                          Отмена
                        </button>
                       
                      </div>
                    )} */}
									</div>
								)}

								<div className={s.comments}>
									{idLesson &&
										playerCommentData?.results.map(
											(comment, index) => (
												<Comment
													commentData={comment}
													key={index}
													profileData={
														profilesMap[comment?.user_id!] ||
														profilesMap[userData?.user_id!] ||
														null
													}
												/>
											),
										)}
								</div>
							</div>
						</div>
						<div className={s.rightSideContainer}>
							<div className={s.adaptiveFilter}>
								<CustomFilter threeTab={true} />
							</div>
							{filteredLessons.map((el, index) => {
								return (
									<RightSideVideo
										key={index}
										lessonData={el}
										profileData={profilesMap[el?.user_id!] || null}
									/>
								)
							})}
						</div>
						<div className={s.rightSideContainerAdaptive}>
							<div className={s.adaptiveFilter}>
								<CustomFilter threeTab={true} />
							</div>
							{filteredLessons.slice(0, 3).map((el, index) => {
								return (
									<AdaptiveCardVideo
										key={index}
										lessonData={el}
										profileData={profilesMap[el?.user_id!] || null}
									/>
								)
							})}
						</div>
					</main>
				</div>
			)}
		</div>
	)
}
