import React, { useState } from "react"
import russia from "../../../../app/assets/createLessonModal/Russia.svg"
import spain from "../../../../app/assets/createLessonModal/SpainActive.svg"
import uk from "../../../../app/assets/createLessonModal/UkActive.svg"
import UAE from "../../../../app/assets/createLessonModal/UaeActive.svg"
import Germany from "../../../../app/assets/createLessonModal/GermanyActive.svg"
import Italy from "../../../../app/assets/createLessonModal/ItalyActive.svg"
import France from "../../../../app/assets/createLessonModal/FranceActive.svg"
import Uzb from "../../../../app/assets/createLessonModal/UzbActive.svg"
import Israel from "../../../../app/assets/createLessonModal/IsraelActive.svg"
import Turkey from "../../../../app/assets/createLessonModal/TurkeyActive.svg"

// Интерфейс для изображения (опционально)
import s from "./selectCountry.module.scss"
interface ImageOption {
	src: string
	alt: string
}

export const SelectCountry = () => {
	// Состояние для хранения выбранных изображений
	const [selectedImages, setSelectedImages] = useState<string[]>([])

	const images: ImageOption[] = [
		{ src: russia, alt: "Russia" },
		{ src: uk, alt: "UK" },
		// { src: spain, alt: "Spain" },
		// { src: UAE, alt: "UAE" },
		// { src: Germany, alt: "Germany" },
		// { src: Italy, alt: "Italy" },
		// { src: France, alt: "France" },
		// { src: Uzb, alt: "Uzb" },
		// { src: Israel, alt: "Israel" },
		// { src: Turkey, alt: "Turkey" },
	]

	const toggleSelect = (alt: string) => {
		setSelectedImages((prevSelected) =>
			prevSelected.includes(alt)
				? prevSelected.filter((item) => item !== alt)
				: [...prevSelected, alt],
		)
	}

	return (
		<div>
			<span className={s.imageContainer}>
				{images.map((image) => (
					<img
						key={image.alt}
						src={image.src}
						alt={image.alt}
						onClick={() => toggleSelect(image.alt)}
						className={`${s.image}`}
					/>
				))}
			</span>
		</div>
	)
}
