import axios from "axios";
import { BASEURL, XURL } from "./apiLessons";
import {
  setPlayerCommentData,
  setPlayerCommentRepliesData,
  setPlayerData,
} from "app/service/player/playerSlice";
import { axiosWithRefreshToken } from "helpers/localStorage.helper";
import {
  IPlayerCommentRepliesState,
  IPlayerCommentState,
  IPlayerDataState,
  IPlayerSendComment,
} from "app/types/type";

export const getPlayerIdWithOutToken = async (
  dispatch: any,
  id: string,
) => {
  try {
    const response = await axios.get(
      `${XURL}/api/v1/lesson/${id}/detail/`,
    );
    const data: IPlayerDataState = response.data;
    dispatch(setPlayerData(data));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};

export const getPlayerId = async (dispatch: any, id: string) => {
  try {
    const response = await axiosWithRefreshToken<IPlayerDataState>(
      `${XURL}/api/v1/lesson/${id}/detail/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    dispatch(setPlayerData(response));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
export const getPlayerComments = async (
  dispatch: any,
  id: number,
) => {
  try {
    const response = await axiosWithRefreshToken<IPlayerCommentState>(
      `${XURL}/api/v1/comments/media/${id}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    dispatch(setPlayerCommentData(response));
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
export const getPlayerCommentsReplies = async (
  dispatch: any,
  id: number, // id comment
) => {
  try {
    const response =
      await axiosWithRefreshToken<IPlayerCommentRepliesState>(
        `${XURL}/api/v1/comments/${id}/replies/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
    console.log(response);
    dispatch(
      setPlayerCommentRepliesData({
        commentId: id,
        replies: response.results,
      }),
    );
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
export const sendPlayerComment = async (
  id: number, // id comment
  text: string,
) => {
  try {
    await axiosWithRefreshToken<IPlayerSendComment>(
      `${XURL}/api/v1/comments/media/${id}/add/`,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        data: {
          text,
        },
      },
    );
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
export const sendPlayerCommentReplies = async (
  id: number, // id comment
  text: string,
) => {
  try {
    await axiosWithRefreshToken<IPlayerSendComment>(
      `${XURL}/api/v1/comments/${id}/reply/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          text,
        },
      },
    );
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
  }
};
