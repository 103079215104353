import React from "react";
import s from "./style.module.scss";
export const SuccessModal = ({ text }: { text: string }) => {
  return (
    <div className={s.container}>
      <section>
        <h1 className={s.title}>MAKEUPDATE</h1>
      </section>
      <p className={s.text}>
        <span>{text}</span>
      </p>
      <button
        className={s.sendButton}
        onClick={() => (window.location.href = "/")}>
        Вернуться на главную страницу
      </button>
    </div>
  );
};
