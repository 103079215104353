import React, { useState } from "react"
import s from "./customSelect.module.scss"
import rus from "../../../../app/assets/Seller/Russia.svg"
import arrowDown from "../../../../app/assets/home/arrowDown.svg"
import UK from "../../../../app/assets/Seller/UK.svg"
import Spain from "../../../../app/assets/Seller/Spain.svg"
import UAE from "../../../../app/assets/Seller/Saud.svg"
import { IRefProps } from "app/types/type"
import { useDispatch, useSelector } from "app/service/hooks/hooks"
import {
	setOpenDoc,
	setOpenProtection,
} from "app/service/home/HomeSlice"

interface IOptionType {
	type?: string
	value: string
	label: string
	ref?: React.RefObject<HTMLDivElement>
}

export const CustomSelectCreateLessonModal = ({
	state,
	registrationRef,
	settingProfileRef,
	selectTutorialsRef,
	paymentRef,
	loadingTutorialsRef,
	socialRef,
	reviewsRef,
	supportsRef,
	drmRef,
	watermarkRef,
	accessControlRef,
}: IRefProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectedOption, setSelectedOption] =
		useState<IOptionType | null>(null)
	const dispatch = useDispatch()
	const options = [
		{
			value: "registration",
			label: "Регистрация и вход",
			ref: registrationRef,
			type: "doc",
		},
		{
			value: "settingProfile",
			label: "Настройка профиля",
			ref: settingProfileRef,
			type: "doc",
		},
		{
			value: "selectTutorials",
			label: "Выбор урока и обучение",
			ref: selectTutorialsRef,
		},
		{
			value: "payment",
			label: "Покупка и оплата",
			ref: paymentRef,
			type: "doc",
		},
		{
			value: "loadingTutorials",
			label: "Загрузка и размещение собственных уроков",
			ref: loadingTutorialsRef,
			type: "doc",
		},
		{
			value: "social",
			label: "Социальное взаимодействие и сообщество",
			ref: socialRef,
			type: "doc",
		},
		{
			value: "reviews",
			label: "Оставление отзывов и оценок",
			ref: reviewsRef,
			type: "doc",
		},
		{
			value: "supports",
			label: "Поддержка и помощь",
			ref: supportsRef,
			type: "doc",
		},
	]
	const options2 = [
		{
			value: "drm",
			label: "DRM шифрование файлов",
			ref: drmRef,
			type: "protection",
		},
		{
			value: "watermark",
			label: "Водяные знаки",
			ref: watermarkRef,
			type: "protection",
		},
		{
			value: "selectTutorials",
			label: "Контроль доступа и индивидуальная авторизация",
			ref: accessControlRef,
			type: "protection",
		},
	]
	const { openProtection, openDoc } = useSelector(
		(state) => state.home,
	)
	const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
		if (ref.current) {
			let yOffset
			if (openDoc) {
				yOffset = -540
			} else {
				yOffset = -360
			}
			const yPosition =
				ref.current.getBoundingClientRect().top +
				window.pageYOffset +
				yOffset
			window.scrollTo({ top: yPosition, behavior: "smooth" })
		}
	}
	// const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
	// 	if (ref && ref.current) {
	// 		ref.current.scrollIntoView({ behavior: "smooth" })
	// 	}
	// }

	const handleOptionClick = (option: IOptionType) => {
		setSelectedOption(option)
		setIsOpen(false)
		if (option.type === "doc") {
			// dispatch(setOpenDoc(true))
			dispatch(setOpenProtection(false))
			if (option.ref && scrollToSection) {
				scrollToSection(option.ref)
			}
		} else if (option.type === "protection") {
			// dispatch(setOpenProtection(true))
			// dispatch(setOpenDoc(false))
			if (option.ref && scrollToSection) {
				scrollToSection(option.ref)
			}
		}
		// Scroll to the corresponding section if ref exists
		// if (option.ref && scrollToSection) {
		// 	scrollToSection(option.ref)
		// }
	}

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	return (
		<div className={s.customSelect}>
			<div className={s.selectedOption} onClick={toggleDropdown}>
				{selectedOption ? (
					<div className={s.optionContent}>
						<div className={s.leftBlock}>{selectedOption.label}</div>
						<span>
							<img
								src={arrowDown}
								alt="arrow"
								className={isOpen ? s.open : s.close}
							/>
						</span>
					</div>
				) : state === 1 ? (
					<span className={s.selectContainer}>
						Документация по использованию платформы
						<img
							src={arrowDown}
							alt="arrow"
							className={isOpen ? s.open : s.close}
						/>
					</span>
				) : (
					<span className={s.selectContainer}>
						Защита видеоконтента
						<img
							src={arrowDown}
							alt="arrow"
							className={isOpen ? s.open : s.close}
						/>
					</span>
				)}
			</div>
			{isOpen && (
				<ul className={s.optionsList}>
					{(state === 1 ? options : options2).map((option) => (
						<li
							key={option.value}
							className={s.optionItem}
							onClick={() => handleOptionClick(option)}>
							{/* <img
								src={option.icon}
								alt={option.label}
								className={s.optionIcon}
								/> */}
							{option.label}
						</li>
					))}
				</ul>
			)}
		</div>
	)
}
