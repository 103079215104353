import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  IGetPlayerIdInitialState,
  IPlayerCommentDataRepliesResults,
  IPlayerCommentRepliesState,
  IPlayerCommentState,
  IPlayerDataState,
} from "app/types/type";

const initialState: IGetPlayerIdInitialState = {
  playerData: null,
  playerCommentData: null,
  // playerCommentDataReplies: null,
  repliesByCommentId: {},
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPlayerData: (
      state,
      action: PayloadAction<IPlayerDataState>,
    ) => {
      state.playerData = action.payload;
    },
    setPlayerCommentData: (
      state,
      action: PayloadAction<IPlayerCommentState>,
    ) => {
      state.playerCommentData = action.payload;
    },
    setPlayerCommentRepliesData: (
      state,
      action: PayloadAction<{
        commentId: number;
        replies: IPlayerCommentDataRepliesResults[];
      }>,
    ) => {
      const { commentId, replies } = action.payload;
      state.repliesByCommentId[commentId] = replies;
    },
    // setPlayerCommentRepliesData: (
    //   state,
    //   action: PayloadAction<IPlayerCommentRepliesState>,
    // ) => {
    //   state.playerCommentDataReplies = action.payload;
    // },

    // setPlayerCommentRepliesById: (
    //   state,
    //   action: PayloadAction<{
    //     commentId: number;
    //     replies: IPlayerCommentDataRepliesResults[];
    //   }>,
    // ) => {
    //   state.repliesByCommentId[action.payload.commentId] =
    //     action.payload.replies;
    // },
  },
});

export const {
  setPlayerData,
  setPlayerCommentData,
  setPlayerCommentRepliesData,
} = playerSlice.actions;

export default playerSlice.reducer;
