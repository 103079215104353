import React, { useState } from "react";
import s from "./faq.module.scss";
import arrowDown from "../../../../../app/assets/home/arrowDown.svg";
export const FAQ = () => {
  const [openElement, setOpenElement] = useState<boolean>(false);
  const toggleOpen = () => {
    setOpenElement(!openElement);
  };
  return (
    <div className={s.wrapper}>
      <section>
        <h1 className={s.title}>FAQ</h1>
      </section>
      <button className={s.element} onClick={toggleOpen}>
        <span>Стоит ли удалять видео или расширить хранилище?</span>
        <img
          src={arrowDown}
          alt='arrowDown'
          className={openElement ? s.arrowUp : s.arrowDown}
        />
      </button>
      {openElement && (
        <p className={s.text}>
          Если продавец удалит видео, просмотры этого видео пропадут
          из его общей статистики, что может привести к снижению
          рейтинга. Рейтинг на платформе определяется исключительно
          количеством просмотров уроков, поэтому удаление видео может
          негативно сказаться на позициях продавца в рейтинге. Вместо
          удаления видео рекомендуется расширить хранилище, чтобы
          сохранить все уроки и продолжать накапливать просмотры.
        </p>
      )}
    </div>
  );
};
